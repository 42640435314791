import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import saveAs from 'file-saver'
import {
  TextField,
  Card,
  CircularProgress,
  Button,
  Checkbox,
} from '@material-ui/core'
import {
  getProfile,
  cleanProfile,
  handleChange,
  getCountries,
} from '../../modules/profile'

import Upload from '../../components/profile/Upload'
import AddressForm from '../../components/profile/AddressForm'
import HeaderContainer from '../header/HeaderContainer'

import DoctorPage from '../../components/profile/DoctorPage'
import PsychoPage from '../../components/profile/PsychoPage'

import './ProfileContainer.css'
import { withTranslation } from 'react-i18next'

class ProfileContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: [],
      saving: false,
      accountNumberError: false,
    }

    this.upload = this.upload.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  componentDidMount() {
    this.props.getProfile()
    this.props.getCountries()
  }

  componentWillUnmount() {
    this.props.cleanProfile()
  }

  handleAccountNumberChange = event => {
    const { handleChange } = this.props
    const replaced = event.target.value.replace(/\s/g, '').replace('-', '')
    if (replaced.match('([0-9]{8}){2,3}') !== null) {
      handleChange('accountNumber', replaced)
      this.setState({
        accountNumberError: false,
      })
    } else {
      handleChange('accountNumber', replaced)
      this.setState({
        accountNumberError: true,
      })
    }
  }

  missingUploadedFiles = () => {
    const {
      profile: {
        medicalDegreeUrl,
        specialistCertificateUrl,
        insuranceUrl,
        identityCardFrontUrl,
        identityCardBackUrl,
        aeekCardFrontUrl,
        aeekCardBackUrl,
        homeAddressCardUrl,
        providerTypeId,
      },
    } = this.props

    const commonFilesMissing =
      medicalDegreeUrl == null ||
      specialistCertificateUrl == null ||
      identityCardFrontUrl == null ||
      identityCardBackUrl == null

    if (commonFilesMissing) {
      return true
    }

    const doctorFilesMissing =
      insuranceUrl == null ||
      identityCardFrontUrl == null ||
      aeekCardFrontUrl == null ||
      aeekCardBackUrl == null

    if (providerTypeId === 1 && doctorFilesMissing) {
      return true
    }

    const psychoFilesMissing = homeAddressCardUrl == null

    if (providerTypeId === 2 && psychoFilesMissing) {
      return true
    }

    return false
  }

  isBillingAddressCountrySelected = address => {
    return address.countryCode !== undefined
  }

  async upload(file, title) {
    if (!file) return
    const { providerProviderTypeId } = this.props.profile
    this.setState(({ uploading }) => ({ uploading: [...uploading, title] }))
    const formData = new FormData()
    formData.append('content', file)
    formData.append('title', title)
    formData.append('providerProviderTypeId', providerProviderTypeId)
    try {
      const {
        data: { title: fileName, location },
      } = await axios.post(`/api/platform/provider/upload/${title}`, formData)
      if (title === 'profile_image') {
        const reader = new FileReader()
        reader.onloadend = async () => {
          await this.props.handleChange('avatar', reader.result, 'file')
        }
        await reader.readAsDataURL(file)
      } else {
        await this.props.handleChange(`${title}Url`, location, 'file')
      }
      this.setState(({ uploading }) => ({
        uploading: uploading.filter(item => item !== fileName),
      }))
    } catch (err) {
      console.error('Upload error', err)
      this.setState(({ uploading }) => ({
        uploading: uploading.filter(item => item !== title),
      }))
    }
  }

  async download(link) {
    try {
      const response = await axios.post('/api/platform/provider/download', {
        link,
      })
      const { file: fileStr, fileName } = response.data
      const fileBlob = await fetch(fileStr).then(res => res.blob())
      saveAs(fileBlob, fileName)
    } catch (err) {
      console.log(err)
    }
  }

  async saveData(event) {
    const {
      chamberMembershipNumber,
      accountNumber,
      acceptedPrivacyPolicy,
      profile: { providerTypeId },
      address,
      countries,
      languages,
    } = this.props

    event.preventDefault()

    try {
      if (!this.isBillingAddressCountrySelected(address)) {
        alert(this.props.t('alert1'))
        return
      }

      if(!Array.isArray(languages) || languages.length === 0) {
        alert(this.props.t('missingLanguagesAlert'))
        return
      }

      const formattedAddress = {
        ...address,
        countryName: countries.find(
          country => country.countryCode === address.countryCode
        ).countryName,
      }

      if (this.missingUploadedFiles()) {
        alert(this.props.t('alert2'))
        return
      }

      this.setState({ saving: true })

      console.log('saving provider type', providerTypeId, languages)
      await axios.put(`/api/platform/provider/data/${providerTypeId}`, {
        accountNumber,
        chamberMembershipNumber,
        acceptedPrivacyPolicy,
        languages,
      })

      if (!address.id) {
        await axios.post(`/api/platform/provider/address`, formattedAddress)
      } else {
        await axios.put(`/api/platform/provider/address`, formattedAddress)
      }
      await this.props.getProfile()
      this.setState({ saving: false })
      alert(
        this.props.t('alert3')
      )
    } catch (err) {
      console.error(err)
      this.setState({ saving: false })
      alert(this.props.t('alert4'))
    }
  }

  render() {
    const {
      profile: {
        avatar,
        name,
        email,
        medicalDegreeUrl,
        specialistCertificateUrl,
        insuranceUrl,
        identityCardFrontUrl,
        identityCardBackUrl,
        aeekCardFrontUrl,
        aeekCardBackUrl,
        homeAddressCardUrl,
        providerTypeId,
      },
      loading,
      chamberMembershipNumber,
      accountNumber,
      acceptedPrivacyPolicy,
      handleChange,
      address,
      countries,
      languages,
    } = this.props
    const { uploading, saving, accountNumberError } = this.state    
    
    console.log('languages =', languages)

    return (
      <div className="profile_management_container">
        <HeaderContainer />
        {loading ? (
          <CircularProgress className="page_loading_indicator" />
        ) : (
          <Fragment>
            <div className="image_row">
              <div className="gradient" />
              <div className="image_upload">
                <div className="profile_image_container">
                  {uploading.includes('profile_image') ? (
                    <CircularProgress className="profile_loading" />
                  ) : (
                    <img
                      alt="profile"
                      onClick={() => this.profile_image_upload.click()}
                      className="profile_image"
                      src={
                        avatar ||
                        `${process.env.PUBLIC_URL}/images/profile_placeholder.png`
                      }
                    />
                  )}
                </div>
                <input
                  ref={ref => (this.profile_image_upload = ref)}
                  className="profile_upload"
                  type="file"
                  onChange={e =>
                    this.upload(e.target.files[0], 'profile_image')
                  }
                  accept=".png,.jpg,.jpeg"
                />
              </div>
            </div>
            <div className="info_row">
              <div>
                <h2 className="name">{name}</h2>
              </div>
              <div>
                <h4 className="email">{email}</h4>
              </div>
            </div>

            <form onSubmit={this.saveData}>
              <React.Fragment>
                {providerTypeId === 1 && (
                  <React.Fragment>
                    <DoctorPage
                      medicalDegreeUrl={medicalDegreeUrl}
                      specialistCertificateUrl={specialistCertificateUrl}
                      aeekCardFrontUrl={aeekCardFrontUrl}
                      aeekCardBackUrl={aeekCardBackUrl}
                      insuranceUrl={insuranceUrl}
                      chamberMembershipNumber={chamberMembershipNumber}
                      handleChange={handleChange}
                      uploading={uploading}
                      upload={this.upload}
                      download={this.download}
                      languages={languages}
                    />
                  </React.Fragment>
                )}
                {providerTypeId === 2 && (
                  <React.Fragment>
                    <PsychoPage
                      medicalDegreeUrl={medicalDegreeUrl}
                      specialistCertificateUrl={specialistCertificateUrl}
                      homeAddressCardUrl={homeAddressCardUrl}
                      handleChange={handleChange}
                      uploading={uploading}
                      upload={this.upload}
                      download={this.download}
                      languages={languages}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>

              <Upload
                name={this.props.t('frontIdCard')}
                downloadLink={identityCardFrontUrl}
                fileName="identityCardFront"
                onSelect={this.upload}
                download={this.download}
                isUploading={uploading.includes('identityCardFront')}
              />

              <Upload
                name={this.props.t('backIdCard')}
                downloadLink={identityCardBackUrl}
                fileName="identityCardBack"
                onSelect={this.upload}
                download={this.download}
                isUploading={uploading.includes('identityCardBack')}
              />

              <Card className="row address_form">
                <AddressForm
                  onChange={handleChange}
                  address={address}
                  countries={countries}
                />
                <TextField
                  className="address_form_input"
                  label={this.props.t('accountNumber')}
                  margin="normal"
                  required
                  placeholder={this.props.t('accountNumberPlaceholder')}
                  inputProps={{
                    pattern:
                      '([0-9]{8}){2,3}|([0-9]{8}\\s{0,}){2,3}|([0-9]{4}\\s{0,}){6}|([0-9]{4}\\s{0,}){4}',
                  }}
                  value={accountNumber || ''}
                  onChange={e => this.handleAccountNumberChange(e)}
                  error={accountNumberError}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Card>

              <Card className="row_privacy_policy">
                <Checkbox
                  onChange={(e, checked) =>
                    handleChange('acceptedPrivacyPolicy', checked)
                  }
                  checked={acceptedPrivacyPolicy || false}
                  inputProps={{ required: true }}
                />
                <div>
                  {this.props.t('aszfStart')}{' '}
                  <a
                    href={
                      providerTypeId === 1
                        ? '/adatkezeles_orvos-20200214.pdf'
                        : '/adatkezeles_pszichologus-20200214.pdf'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    {this.props.t('aszfMid')}
                  </a>{' '}
                  {this.props.t('aszfEnd')}
                </div>
              </Card>

              <Card className="row">
                <Button
                  color="secondary"
                  type="submit"
                  className="save_button"
                  variant="contained"
                  fullWidth
                >
                  {saving ? <CircularProgress color="inherit" /> : this.props.t('save')}
                </Button>
              </Card>
            </form>
          </Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({
  profile: {
    profile,
    loading,
    chamberMembershipNumber,
    accountNumber,
    acceptedPrivacyPolicy,
    address,
    countries,
    languages,
  },
}) => ({
  profile,
  loading,
  chamberMembershipNumber,
  accountNumber,
  acceptedPrivacyPolicy,
  address,
  countries,
  languages,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfile,
      getCountries,
      cleanProfile,
      handleChange,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('profileContainer')(ProfileContainer))
