import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  formContainer: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit,
  },
  headerContainer: {
    position: 'relative',
    top: '-16px',
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  headerText: {
    color: 'white',
    padding: '10px',
  },
})

class TipForm extends React.Component {
  state = {
    title: '',
    description: '',
    titleEn: '',
    descriptionEn: '',
    id: null,
    init: false,
    status: '',
  }

  handleChange = () => event => {
    console.log(event.target.value)
    switch(event.target.id){
      case "title": 
        this.setState({
          title: event.target.value,
        })
        break
      case "titleEn": 
        this.setState({
          titleEn: event.target.value,
        })
        break
      case "description": 
        this.setState({
          description: event.target.value,
        })
        break
      case "descriptionEn": 
        this.setState({
          descriptionEn: event.target.value,
        })
        break
    }
    
  }

  submitForm = async event => {
    const payload = this.state
    const { onSubmit } = this.props
    event.preventDefault()

    try {
      this.setState({
        status: 'submitting',
      })
      await onSubmit(payload)
    } catch (err) {
      this.setState({
        status: 'error',
      })
    }
  }

  handleSnackClose = () => {
    this.setState({
      status: '',
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { tip } = props
    const { init } = state

    if (init) {
      return state
    }

    return {
      title: tip.title || '',
      titleEn: tip.titleEn || '',
      description: tip.description || '',
      descriptionEn: tip.descriptionEn || '',
      id: tip.id || null,
      init: true,
    }
  }

  render() {
    const { classes, onBackClick } = this.props
    const { title, titleEn, id, description, descriptionEn, status } = this.state
    return (
      <React.Fragment>
        <Grid container className={classes.formContainer} direction="column">
          <Grid className={classes.headerContainer} item xs={12}>
            <Typography
              className={classes.headerText}
              variant="title"
              gutterBottom
            >
              {id && <span>Tipp szerkesztés</span>}
              {!id && <span>Tipp felvétel</span>}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.submitForm} autoComplete="off">
              <Grid container direction="column">
                <Grid item xs={12}>
                  <TextField
                    required
                    id="title"
                    label="Megnevezés"
                    className={classes.textField}
                    value={title}
                    onChange={this.handleChange('title')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="titleEn"
                    label="Megnevezés angol"
                    className={classes.textField}
                    value={titleEn}
                    onChange={this.handleChange('titleEn')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="description"
                    label="Leírás"
                    className={classes.textField}
                    value={description}
                    onChange={this.handleChange('description')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="descriptionEn"
                    label="Leírás angol"
                    className={classes.textField}
                    value={descriptionEn}
                    onChange={this.handleChange('descriptionEn')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  {status !== 'submitting' && (
                    <React.Fragment>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Mentés
                      </Button>
                      <Button
                        onClick={onBackClick}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Vissza
                      </Button>
                    </React.Fragment>
                  )}
                  {status === 'submitting' && (
                    <CircularProgress className={classes.progress} />
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status === 'error'}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hiba a mentés során!</span>}
        ></Snackbar>
      </React.Fragment>
    )
  }
}

TipForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TipForm)
