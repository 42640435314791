export default async function confirmDelete(array, id, propertyName) {
  const filteredArray = array.filter(
    e => e.id === id
  )
  if (filteredArray.length === 0) {
    alert('Hiba: Ez az ID nem létezik')
    return false
  }

  // Unlikely, but just to make sure...
  if (filteredArray.length > 1) {
    alert('Hiba: Duplikált ID')
    return false
  }

  const foundElement = filteredArray[0]
  if (window.confirm(`Biztos véglegesen törölni akarod? (${foundElement[propertyName]})`)) {
    return true
  }
  return false
}
