import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { logoutUser } from '../../modules/user'

import Header from '../../components/header/Header'

class HeaderContainer extends React.Component {
  render() {
    const { logoutUser } = this.props
    return <Header onLogout={logoutUser} />
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logoutUser,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(HeaderContainer)
