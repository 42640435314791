import axios from 'axios'

export const GET_USER_PROFILE = 'get_user_profile'
export const CLEAN_USER_PROFILE = 'clean_user_profile'
export const INPUT_CHANGE = 'input_change'
export const ADDRESS_CHANGE = 'address_change'
export const ADDRESS_INPUT_CHANGE = 'address_input_change'
export const FILE_CHANGE = 'file_change'
export const GET_COUNTRIES = 'get_countries'

const initialState = {
  profile: {},
  loading: true,
  address: {},
  countries: [],
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case GET_USER_PROFILE:
      return {
        ...state, profile: payload, loading: false
      }
      break
    case INPUT_CHANGE:
      return {
        ...state, [payload.target]: payload.value
      }
      break
    case ADDRESS_CHANGE:
      return {
        ...state, address: payload
      }
      break
    case ADDRESS_INPUT_CHANGE:
      return {
        ...state,
        address: {
          ...state.address,
          [payload.target]: payload.value
        },
      }
      break
    case FILE_CHANGE:
      return {
        ...state, profile: {
          ...state.profile,
          [payload.target]: payload.value
        }
      }
      break
    case CLEAN_USER_PROFILE:
      return initialState
      break
    case GET_COUNTRIES:
      return {
        ...state, countries: payload
      }
      break
    default:
      return state
  }
}

export const getProfile = () => async dispatch => {
  try {
    const {
      data: {
        id: userId,
        firstName,
        lastName,
        namePrefix,
        email,
        providerTypes,
      },
    } = await axios.get('/api/platform/user/profile')

    const {
      data: providerData
    } = await axios.get(
      `/api/platform/user/providerData/${providerTypes[0]}`
    )

    const {
      id: providerProviderTypeId,
      billingData: address,
      chamberMembershipNumber,
      identityCardFrontUrl,
      identityCardBackUrl,
      aeekCardFrontUrl,
      aeekCardBackUrl,
      homeAddressCardUrl,
      chamberMembershipUrl,
      insuranceUrl,
      medicalDegreeUrl,
      operatingLicenseUrl,
      specialistCertificateUrl,
      accountNumber,
      avatar,
      acceptedPrivacyPolicy,
      languages,
    } = providerData

    const response = {
      providerTypeId: providerTypes[0],
      providerProviderTypeId,
      email,
      identityCardFrontUrl,
      identityCardBackUrl,
      aeekCardFrontUrl,
      aeekCardBackUrl,
      homeAddressCardUrl,
      chamberMembershipUrl,
      insuranceUrl,
      medicalDegreeUrl,
      operatingLicenseUrl,
      specialistCertificateUrl,
      userId,
      avatar,
      name: `${namePrefix != null ? namePrefix : ''} ${lastName} ${firstName}`,
      accountNumber,
    }
    
    dispatch({
      type: GET_USER_PROFILE,
      payload: response
    })
    dispatch({
      type: INPUT_CHANGE,
      payload: {
        target: 'chamberMembershipNumber',
        value: chamberMembershipNumber,
      },
    })
    dispatch({
      type: INPUT_CHANGE,
      payload: {
        target: 'accountNumber',
        value: accountNumber
      },
    })
    dispatch({
      type: INPUT_CHANGE,
      payload: {
        target: 'acceptedPrivacyPolicy',
        value: acceptedPrivacyPolicy,
      },
    })
    dispatch({
      type: INPUT_CHANGE,
      payload: {
        target: 'languages',
        value: languages,
      },
    })    
    if (address) {
      dispatch({
        type: ADDRESS_CHANGE,
        payload: address
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export const upload = payload => async dispatch => {
  try {} catch (err) {
    console.log(err)
  }
}

export const cleanProfile = () => async dispatch => {
  dispatch({
    type: CLEAN_USER_PROFILE
  })
}

export const handleChange = (target, value, object) => async dispatch => {
  if (object === 'address') {
    dispatch({
      type: ADDRESS_INPUT_CHANGE,
      payload: {
        target,
        value
      }
    })
  }
  if (object === 'file') {
    dispatch({
      type: FILE_CHANGE,
      payload: {
        target,
        value
      }
    })
  }
  dispatch({
    type: INPUT_CHANGE,
    payload: {
      target,
      value
    }
  })
  console.log(`INPUT_CHANGE ${target}, ${value}`)
}

export const getCountries = () => async dispatch => {
  try {
    const {
      data
    } = await axios.get('/api/platform/provider/country')
    dispatch({
      type: GET_COUNTRIES,
      payload: data
    })
  } catch (err) {
    console.log(err)
  }
}
