/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import * as utils from './utils'

const VouchersTable = ({ vouchers, onEdit, onDelete }) => {
  const hasVouchers = Array.isArray(vouchers) && vouchers.length > 0

  const handleEdit = voucher => {
    if (onEdit instanceof Function) {
      onEdit(voucher)
    }
  }

  const handleDelete = voucher => {
    if (onDelete instanceof Function) {
      onDelete(voucher)
    }
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>Azonosító</TableCell>
            <TableCell rowSpan={2}>Kuponkód</TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              Kedvezmény
            </TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              Érvényesség
            </TableCell>
            <TableCell rowSpan={2}>Felhasználás köre</TableCell>
            <TableCell rowSpan={2}>Darabszám</TableCell>
            <TableCell rowSpan={2}>Létrehozás dátuma</TableCell>
            <TableCell rowSpan={2}>Műveletek</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>típusa</TableCell>
            <TableCell>mértéke</TableCell>
            <TableCell>kezdete</TableCell>
            <TableCell>vége</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!hasVouchers && (
            <TableRow>
              <TableCell
                colSpan={10}
                align="center"
                style={{ textAlign: 'center' }}
              >
                Nincs kuponkód
              </TableCell>
            </TableRow>
          )}
          {hasVouchers &&
            vouchers.map(voucher => (
              <TableRow key={voucher.code}>
                <TableCell>{voucher.id}</TableCell>
                <TableCell>
                  {voucher.code}
                  {utils.isExpired(voucher) && <Chip label="Lejárt" variant="outlined" /> }
                </TableCell>
                <TableCell>
                  {utils.formatDiscountType(voucher.discountType)}
                </TableCell>
                <TableCell>
                  {utils.formatDiscount(voucher.discountType, voucher.discount)}
                </TableCell>
                <TableCell>{utils.formatDate(voucher.validFrom)}</TableCell>
                <TableCell>{utils.formatDate(voucher.validTo)}</TableCell>
                <TableCell>
                  {utils.formatUsageLimit(voucher.limitedTo)}
                </TableCell>
                <TableCell>{voucher.count}</TableCell>
                <TableCell>{utils.formatDate(voucher.createdAt)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => handleEdit(voucher)}
                  >
                    Szerkesztés
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleDelete(voucher)}
                  >
                    Törlés
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  )
}

VouchersTable.displayName = 'VouchersTable'

VouchersTable.propTypes = {}

VouchersTable.defaultProps = {}

export default VouchersTable
export { VouchersTable }
