import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'
import saveAs from 'file-saver'
import ExcelJS from 'exceljs'

import {
  getDoctors,
  createUser,
  updateUser,
  deleteUser,
  getProviderTypes,
  getProviderProfileById,
  getProviderProfiles,
  updateProviderTypeData,
} from '../../modules/admin'

import DoctorTable from '../../components/doctor/DoctorTable'
import DoctorForm from '../../components/doctor/DoctorForm'
import DoctorCardList from '../../components/doctor/DoctorCardList'

import confirmDelete from '../../utils/confirmDelete'
import formatDate from '../../utils/dateFormatter'

class DoctorContainer extends React.Component {
  state = {
    showDoctorForm: false,
    selectedDoctor: {},
    searchText: '',
    selectedProviderType: {},
  }

  async componentDidMount() {
    await this.props.getProviderTypes()
    this.setState(
      {
        selectedProviderType: this.props.providerTypes[0],
      },
      () => {
        this.props.getDoctors(null, this.state.selectedProviderType.id)
      }
    )
  }

  showForm = () => {
    this.setState({
      showDoctorForm: true,
    })
  }

  handleEdit = async doctor => {
    await this.props.getProviderProfileById(doctor.id)
    this.setState({
      selectedDoctor: doctor,
      showDoctorForm: true,
    })
  }

  handleExport = async () => {
    try {
      await this.props.getProviderProfiles(this.state.selectedProviderType.id)
      console.log(this.props.profiles)

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet()

      const defaultColumns = [
        { header: 'ID', key: 'userId', width: 7 },
        { header: 'Titulus', key: 'namePrefix', width: 10 },
        { header: 'Vezetéknév', key: 'lastName', width: 32 },
        { header: 'Keresztnév', key: 'firstName', width: 32 },
        { header: 'Email', key: 'email', width: 32 },
        { header: 'Telefonszám', key: 'phoneNumber', width: 16 },
        { header: 'Születési dátum', key: 'birthday', width: 20 },
        { header: 'Regisztráció ideje', key: 'createdAt', width: 20 },
        { header: 'Jóváhagyott', key: 'validated', width: 20 },
        { header: 'ÁSZF elfogadva', key: 'acceptedPrivacyPolicy', width: 20 },
        {
          header: 'Számlázási cégnév',
          key: 'billingAddressUserGivenName',
          width: 32,
        },
        {
          header: 'Számlázási cím',
          key: 'billingAddressStringRepresentation',
          width: 32,
        },
        { header: 'Adószám', key: 'billingAddressVatNumber', width: 32 },
        { header: 'Számlaszám', key: 'accountNumber', width: 32 },
      ]

      const providerTypeColumns =
        this.state.selectedProviderType.id === 1 ? [
          {
            header: 'Nyílvántartási szám',
            key: 'chamberMembershipNumber',
            width: 32,
          },
          {
            header: 'Általános orvosi diploma',
            key: 'medicalDegreeUrl',
            width: 32,
          },
          {
            header: 'Szakorvosi végzettség',
            key: 'specialistCertificateUrl',
            width: 32,
          },
          {
            header: 'ÁEEK kártya elől',
            key: 'aeekCardFrontUrl',
            width: 32,
          },
          {
            header: 'ÁEEK kártya hátul',
            key: 'aeekCardBackUrl',
            width: 32,
          },
          {
            header: 'Felelősség biztosítás',
            key: 'insuranceUrl',
            width: 32,
          },
        ] : [
          {
            header: 'Pszichologus diploma',
            key: 'medicalDegreeUrl',
            width: 32,
          },
          {
            header: 'Szakpszichológusi diploma',
            key: 'specialistCertificateUrl',
            width: 32,
          },
          {
            header: 'Lakcímkártya',
            key: 'homeAddressCardUrl',
            width: 32,
          },
        ]

      worksheet.columns = [
        ...defaultColumns,
        ...providerTypeColumns,
        {
          header: 'Személyi igazolvány elől',
          key: 'identityCardFrontUrl',
          width: 32,
        },
        {
          header: 'Személyi igazolvány hátul',
          key: 'identityCardBackUrl',
          width: 32,
        },
      ]

      const headerRow = worksheet.getRow(1)
      headerRow.font = { bold: true }

      this.props.profiles.forEach(profile => {
        const { stringRepresentation, userGivenName, vatNumber } =
          profile.billingData || {}

        const defaultRows = {
          userId: profile.userId,
          namePrefix: profile.namePrefix,
          lastName: profile.lastName,
          firstName: profile.firstName,
          email: profile.email,
          phoneNumber: profile.phoneNumber,
          birthday: profile.birthday,
          createdAt: formatDate(profile.createdAt),
          validated: profile.validated ? 'Igen' : 'Nem',
          acceptedPrivacyPolicy: profile.acceptedPrivacyPolicy ? 'Igen' : 'Nem',
          billingAddressUserGivenName: userGivenName,
          billingAddressStringRepresentation: stringRepresentation,
          billingAddressVatNumber: vatNumber,
          accountNumber: profile.accountNumber,
        }

        const providerTypeRows =
          this.state.selectedProviderType.id === 1 ? {
            chamberMembershipNumber: profile.chamberMembershipNumber,
            aeekCardFrontUrl: profile.aeekCardFrontUrl
              ? 'Feltöltve'
              : 'Nem elérhető',
            aeekCardBackUrl: profile.aeekCardBackUrl
              ? 'Feltöltve'
              : 'Nem elérhető',
            insuranceUrl: profile.insuranceUrl
              ? 'Feltöltve'
              : 'Nem elérhető',
          } : {
            homeAddressCardUrl: profile.homeAddressCardUrl
              ? 'Feltöltve'
              : 'Nem elérhető',
          }

        worksheet.addRow({
          ...defaultRows,
          medicalDegreeUrl: profile.medicalDegreeUrl
            ? 'Feltöltve'
            : 'Nem elérhető',
          specialistCertificateUrl: profile.specialistCertificateUrl
            ? 'Feltöltve'
            : 'Nem elérhető',
          ...providerTypeRows,
          identityCardFrontUrl: profile.identityCardFrontUrl
            ? 'Feltöltve'
            : 'Nem elérhető',
          identityCardBackUrl: profile.identityCardBackUrl
            ? 'Feltöltve'
            : 'Nem elérhető',
        })
      })

      const buf = await workbook.xlsx.writeBuffer()
      saveAs(new Blob([buf]), `${this.state.selectedProviderType.name}.xlsx`)
    } catch (err) {
      console.log(err)
    }
  }

  submitForm = async payload => {
    if (!payload.id) {
      await this.props.createUser({ ...payload, userTypeId: 1 })
    } else {
      await this.props.updateUser({ ...payload, userTypeId: 1 })
      await this.props.updateProviderTypeData(payload)
    }
    const { searchText, selectedProviderType } = this.state
    await this.props.getDoctors(searchText, selectedProviderType.id)
    this.setState({
      showDoctorForm: false,
      selectedDoctor: {},
    })
  }

  handleDelete = async ({ id }) => {
    const canDelete = await confirmDelete(this.props.doctors, id, 'email')
    if (canDelete) {
      await this.props.deleteUser({ id })
      const { searchText, selectedProviderType } = this.state
      await this.props.getDoctors(searchText, selectedProviderType.id)
    }
  }

  handleTypeSelected = async selectedProviderType => {
    this.setState({
      selectedProviderType,
    })
    const { searchText } = this.state
    await this.props.getDoctors(searchText, selectedProviderType.id)
  }

  handleSearch = async searchText => {
    const { selectedProviderType } = this.state
    await this.props.getDoctors(searchText, selectedProviderType.id)
  }

  handleBack = () => {
    this.setState({
      showDoctorForm: false,
      selectedDoctor: {},
    })
  }

  handleValidationSwitch = async doctor => {
    doctor.validated = !doctor.validated
    await this.props.updateUser({ ...doctor, userTypeId: 1 })
    const { searchText, selectedProviderType } = this.state
    await this.props.getDoctors(searchText, selectedProviderType.id)
  }

  render() {
    const { doctors, providerTypes, profile } = this.props
    const { showDoctorForm, selectedDoctor, selectedProviderType } = this.state
    return (
      <React.Fragment>
        {!showDoctorForm && (
          <React.Fragment>
            <Hidden only={['sm', 'xs']}>
              <DoctorTable
                handleAdd={this.showForm}
                handleSearch={this.handleSearch}
                handleDelete={this.handleDelete}
                handleTypeSelected={this.handleTypeSelected}
                handleEdit={this.handleEdit}
                handleExport={this.handleExport}
                handleValidationSwitch={this.handleValidationSwitch}
                formatDate={formatDate}
                doctors={doctors}
                providerTypes={providerTypes}
                selectedProviderType={selectedProviderType}
              />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <DoctorCardList
                handleAdd={this.showForm}
                handleDelete={this.handleDelete}
                handleTypeSelected={this.handleTypeSelected}
                handleEdit={this.handleEdit}
                handleExport={this.handleExport}
                handleValidationSwitch={this.handleValidationSwitch}
                formatDate={formatDate}
                doctors={doctors}
                providerTypes={providerTypes}
                selectedProviderType={selectedProviderType}
              />
            </Hidden>
          </React.Fragment>
        )}
        {showDoctorForm && (
          <DoctorForm
            doctor={selectedDoctor}
            profile={profile}
            providerTypes={providerTypes}
            selectedProviderType={selectedProviderType}
            onBackClick={this.handleBack}
            onSubmit={this.submitForm}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  doctors: admin.doctors,
  providerTypes: admin.providerTypes,
  profile: admin.profile,
  profiles: admin.profiles,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDoctors,
      createUser,
      updateUser,
      deleteUser,
      getProviderTypes,
      getProviderProfileById,
      getProviderProfiles,
      updateProviderTypeData,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DoctorContainer)
