const strings = {
  md: 'Orvos',
  missing: 'Nincs megadva',
  noLimit: 'Nem limitált',
  percentage: 'Százalékos kedvezmény',
  therapist: 'Pszichológus',
}

const formatDate = date => {
  if (date) {
    return new Date(date).toLocaleString()
  }

  return date
}

const formatDiscount = (discountType, discount) => {
  if (!discountType || !discount) {
    return discount
  }

  switch (discountType.toLowerCase()) {
    case 'percentage':
      return `${discount}%`
  }

  return discount
}

const formatDiscountType = discountType => {
  if (!discountType) {
    return strings.missing
  }

  return strings[discountType]
}

const formatUsageLimit = limitedTo => {
  if (Array.isArray(limitedTo) && limitedTo.length) {
    const formatted = limitedTo.map(limit => strings[limit]).sort()
    return formatted.join(', ')
  }

  return strings.noLimit
}

const isExpired = voucher => {
  if (!voucher) {
    return false
  }

  if (new Date(voucher.validTo) <= new Date()) {
    return true
  }
}

export { formatDate }
export { formatDiscount }
export { formatDiscountType }
export { formatUsageLimit }
export { isExpired }
