import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuIcon from '@material-ui/icons/Menu'

import Search from '../common/Search'
import TypeSelector from '../common/TypeSelector'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  btnContainer: {
    textAlign: 'right',
  },
  searchContainer: {
    textAlign: 'left',
  },
  dragContainer: {
    cursor: 'row-resize',
  },
})

const SymptomTable = ({
  symptoms,
  providerTypes,
  selectedProviderType,
  classes,
  handleAdd,
  handleEdit,
  handleDelete,
  handleSearch,
  handleTypeSelected,
  handleSortOrder,
  handleOnDragStart,
  handleOnDragOver,
  handleOnDragEnd,
}) => (
  <React.Fragment>
    <Grid container justify="center">
      <Grid className={classes.searchContainer} item md={4}>
        <Search handleSearch={handleSearch} />
      </Grid>
      <Grid className={classes.searchContainer} item md={4}>
        <TypeSelector
          types={providerTypes}
          selectedType={selectedProviderType}
          displayParam="name"
          handleTypeSelected={handleTypeSelected}
        />
      </Grid>
      <Grid className={classes.btnContainer} item md={4}>
        <Button
          onClick={handleAdd}
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Megnevezés</TableCell>
          <TableCell>Megnevezés angol</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {symptoms.map((symptom, idx) => {
          return (
            <TableRow key={symptom.id}>
              <TableCell
                draggable
                onDragStart={e => handleOnDragStart(e, idx)}
                onDragOver={e => handleOnDragOver(e, idx)}
                onDragEnd={handleOnDragEnd}
                onDrop={() => {
                  handleSortOrder(symptoms)
                }}
              >
                <IconButton
                  className={`${classes.button} ${classes.dragContainer}`}
                >
                  <MenuIcon />
                </IconButton>
                {symptom.title}
              </TableCell>
              <TableCell>
                {symptom.titleEn}
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    handleEdit(symptom)
                  }}
                  className={classes.button}
                  aria-label="Edit"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDelete({ id: symptom.id })
                  }}
                  className={classes.button}
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

export default withStyles(styles)(SymptomTable)
