import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  countContainer: {
    boxShadow: 'none',
    textAlign: 'left',
    marginTop: '5px',
  },
  label: {
    color: '#1E2864',
    fontSize: '20px',
  },
})

const StatusCard = ({ label, count, classes }) => (
  <React.Fragment>
    <Grid container justify='center'>
      <Grid className={classes.countContainer} item md={12}>
        <Typography className={classes.label}>
          {`${label}: ${count}`}
        </Typography>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default withStyles(styles)(StatusCard)
