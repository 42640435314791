import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { registerUser } from '../../modules/user'

import BestPracticeComponent from '../../components/bestPractice/BestPracticeComponent'

class BestPracticeContainer extends React.Component {
  render() {
    const { registerUser, authError } = this.props
    return <BestPracticeComponent error={authError} onSubmit={registerUser} />
  }
}

const mapStateToProps = ({ user }) => ({
  isAuthenticating: user.isAuthenticating,
  authError: user.authError,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerUser,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(BestPracticeContainer)
