import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => ({
  button: {
    margin: 0,
    marginBottom: '20px',
    width: '100%',
  },
  editButton: {
    width: '100%',
  },
  btnContainer: {
    textAlign: 'right',
  },
  card: {
    boxShadow: 'none',
  },
})

const TipCardList = ({
  tips,
  classes,
  handleAdd,
  handleEdit,
  handleDelete,
}) => (
  <React.Fragment>
    <div className={classes.btnContainer}>
      <Button
        onClick={handleAdd}
        size="small"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        <AddIcon />
      </Button>
    </div>
    {tips.map(tip => {
      return (
        <Card className={classes.card} key={tip.id}>
          <CardContent>
            <Typography component="p">{`Megnevezés: ${tip.title}`}</Typography>
            <Typography component="p">
              {`Leírás: ${tip.description}`}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                handleEdit(tip)
              }}
              size="small"
              variant="contained"
              color="primary"
              className={classes.editButton}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                handleDelete({ id: tip.id })
              }}
              size="small"
              variant="contained"
              color="primary"
              className={classes.editButton}
            >
              <DeleteIcon />
            </Button>
          </CardActions>
        </Card>
      )
    })}
  </React.Fragment>
)

export default withStyles(styles)(TipCardList)
