import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  loginCardContainer: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit * 3,
    '&:visited': {
      color: 'white',
    },
  },
  progress: {
    margin: theme.spacing.unit * 3,
  },
  headerContainer: {
    width: 'fit-content',
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
    padding: '5%',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  header: {
    color: 'white',
  },
})

class LoginCard extends React.Component {
  state = {
    email: '',
    password: '',
    status: '',
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleSnackClose = () => {
    this.setState({
      status: '',
    })
  }

  submitForm = async event => {
    const { email, password } = this.state
    const { onSubmit } = this.props
    event.preventDefault()

    try {
      this.setState({
        status: 'submitting',
      })
      await onSubmit({ email, password })
    } catch (err) {
      this.setState({
        status: 'error',
      })
    }
  }

  render() {
    const { classes } = this.props
    const { email, password, status } = this.state
    return (
      <React.Fragment>
        <Grid className={classes.headerContainer} item xs={12}>
          <Typography className={classes.header} variant="title" gutterBottom>
            Üdvözöljük
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={this.submitForm} autoComplete="off">
            <Grid container direction="column">
              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  label="Email"
                  className={classes.textField}
                  value={email}
                  onChange={this.handleChange('email')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="password"
                  label="Jelszó"
                  className={classes.textField}
                  type="password"
                  margin="normal"
                  value={password}
                  onChange={this.handleChange('password')}
                />
              </Grid>
              <Grid item xs={12}>
                {status !== 'submitting' && (
                  <Grid container direction="column">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Bejelentkezés
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      href="/register"
                    >
                      Szakember regisztráció
                    </Button>
                  </Grid>
                )}
                {status === 'submitting' && (
                  <CircularProgress className={classes.progress} />
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status === 'error'}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hiba bejelentkezés során!</span>}
        ></Snackbar>
      </React.Fragment>
    )
  }
}

LoginCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginCard)
