import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'

import { getFeedbacks } from '../../modules/admin'

import FeedbackTable from '../../components/feedback/FeedbackTable'
import FeedbackCardList from '../../components/feedback/FeedbackCardList'

class FeedbackContainer extends React.Component {
  componentDidMount() {
    this.props.getFeedbacks()
  }

  handleSearch = async searchText => {
    await this.props.getFeedbacks(searchText)
  }

  render() {
    const { feedbacks } = this.props
    return (
      <React.Fragment>
        <Hidden only={['sm', 'xs']}>
          <FeedbackTable
            handleSearch={this.handleSearch}
            feedbacks={feedbacks}
          />
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <FeedbackCardList feedbacks={feedbacks} />
        </Hidden>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  feedbacks: admin.feedbacks,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFeedbacks,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContainer)
