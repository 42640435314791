import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'

import TypeSelector from '../common/TypeSelector'

const styles = theme => ({
  searchContainer: {
    textAlign: 'center',
  },
})

const WaitingTimeTable = ({
  waitingTimes,
  classes,
  handleTypeSelected,
  providerTypes,
  selectedProviderType,
}) => (
  <React.Fragment>
    <Grid container justify="center">
      <Grid className={classes.searchContainer} item md={12}>
        <TypeSelector
          types={providerTypes}
          selectedType={selectedProviderType}
          displayParam="name"
          handleTypeSelected={handleTypeSelected}
        />
      </Grid>
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Időintervallum kezdete</TableCell>
          <TableCell>Időintervallum vége</TableCell>
          <TableCell>Hívások száma</TableCell>
          <TableCell>Átlagos várakozási idő</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {waitingTimes.map(waitingTime => {
          return (
            <TableRow key={waitingTime.id}>
              <TableCell>{waitingTime.timeIntervalStart}</TableCell>
              <TableCell>{waitingTime.timeIntervalEnd}</TableCell>
              <TableCell>{waitingTime.elementCount}</TableCell>
              <TableCell>{waitingTime.averageWaitingTime}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

export default withStyles(styles)(WaitingTimeTable)
