import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import TypeSelector from '../common/TypeSelector'

import { withStyles } from '@material-ui/core/styles'
import FormControl from '../../../node_modules/@material-ui/core/FormControl'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  formContainer: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit,
  },
  headerContainer: {
    position: 'relative',
    top: '-16px',
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  headerText: {
    color: 'white',
    padding: '10px',
  },
  selectContainer: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
})

class AddressForm extends React.Component {
  state = {
    countryCode: '',
    countryName: '',
    postalCode: '',
    city: '',
    streetAddress: '',
    misc: '',
    userGivenName: '',
    id: null,
    init: false,
    status: '',
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleTypeSelected = selectedCountry => {
    this.setState({
      countryCode: selectedCountry.countryCode,
      countryName: selectedCountry.countryName,
    })
  }

  submitForm = async event => {
    const payload = this.state
    const formattedPayload = this.formatPayload(payload)
    const { onSubmit } = this.props
    event.preventDefault()
    try {
      this.setState({
        status: 'submitting',
      })
      await onSubmit(formattedPayload)
    } catch (err) {
      this.setState({
        status: 'error',
      })
    }
  }

  formatPayload = payload => {
    const { postalCode } = payload
    const formatted = payload
    formatted.postalCode = parseInt(postalCode)
    return formatted
  }

  handleSnackClose = () => {
    this.setState({
      status: '',
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { address } = props
    const { init } = state

    if (init) {
      return state
    }

    return {
      countryCode: address.countryCode || '',
      countryName: address.countryName || '',
      postalCode: address.postalCode || '',
      city: address.city || '',
      streetAddress: address.streetAddress || '',
      misc: address.misc || '',
      userGivenName: address.userGivenName || '',
      id: address.id || null,
      init: true,
    }
  }

  render() {
    const { classes, onBackClick, countries } = this.props
    const {
      countryCode,
      postalCode,
      city,
      streetAddress,
      misc,
      userGivenName,
      id,
      status,
    } = this.state
    return (
      <React.Fragment>
        <Grid container className={classes.formContainer} direction="column">
          <Grid className={classes.headerContainer} item xs={12}>
            <Typography
              className={classes.headerText}
              variant="title"
              gutterBottom
            >
              {id && <span>Cím szerkesztés</span>}
              {!id && <span>Cím felvétel</span>}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.submitForm} autoComplete="off">
              <Grid container direction="column">
                <Grid item xs={12}>
                  <FormControl className={classes.selectContainer}>
                    <TypeSelector
                      className={classes.textField}
                      types={countries}
                      selectedType={
                        countries.find(it => it.countryCode === countryCode) ||
                        countries[0]
                      }
                      displayParam="countryName"
                      handleTypeSelected={this.handleTypeSelected}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    type="number"
                    id="postalCode"
                    label="Irányítószám"
                    className={classes.textField}
                    value={postalCode}
                    onChange={this.handleChange('postalCode')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="city"
                    label="Város"
                    className={classes.textField}
                    margin="normal"
                    value={city}
                    onChange={this.handleChange('city')}
                  />
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="streetAddress"
                      label="Cím"
                      className={classes.textField}
                      value={streetAddress}
                      onChange={this.handleChange('streetAddress')}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="misc"
                      label="Emelet, ajtó, stb"
                      className={classes.textField}
                      value={misc || ''}
                      onChange={this.handleChange('misc')}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="userGivenName"
                      label="Saját megnevezés"
                      className={classes.textField}
                      value={userGivenName}
                      onChange={this.handleChange('userGivenName')}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {status !== 'submitting' && (
                    <React.Fragment>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Mentés
                      </Button>
                      <Button
                        onClick={onBackClick}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Vissza
                      </Button>
                    </React.Fragment>
                  )}
                  {status === 'submitting' && (
                    <CircularProgress className={classes.progress} />
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status === 'error'}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hiba a mentés során!</span>}
        ></Snackbar>
      </React.Fragment>
    )
  }
}

AddressForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AddressForm)
