import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import Search from '../common/Search'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  btnContainer: {
    textAlign: 'right',
  },
  searchContainer: {
    textAlign: 'left',
  },
})

const TipTable = ({
  tips,
  classes,
  handleAdd,
  handleEdit,
  handleDelete,
  handleSearch,
}) => (
  <React.Fragment>
    <Grid container justify="center">
      <Grid className={classes.searchContainer} item md={8}>
        <Search handleSearch={handleSearch} />
      </Grid>
      <Grid className={classes.btnContainer} item md={4}>
        <Button
          onClick={handleAdd}
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Megnevezés</TableCell>
          <TableCell>Megnevezés angol</TableCell>
          <TableCell>Leírás</TableCell>
          <TableCell>Leírás angol</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tips.map(tip => {
          return (
            <TableRow key={tip.id}>
              <TableCell>{tip.title}</TableCell>
              <TableCell>{tip.titleEn}</TableCell>
              <TableCell>{tip.description}</TableCell>
              <TableCell>{tip.descriptionEn}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    handleEdit(tip)
                  }}
                  className={classes.button}
                  aria-label="Edit"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDelete({ id: tip.id })
                  }}
                  className={classes.button}
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

export default withStyles(styles)(TipTable)
