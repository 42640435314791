import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'

import { withStyles } from '@material-ui/core/styles'

import TypeSelector from '../common/TypeSelector'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  registrationCardContainer: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit * 3,
    '&:visited': {
      color: 'black',
    },
  },
  progress: {
    margin: theme.spacing.unit * 3,
  },
  headerContainer: {
    width: 'fit-content',
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
    padding: '5%',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  welcomeTextContainer: {
    margin: '25px',
  },
  header: {
    color: 'white',
  },
  providerTypeSelector: {
    width: '90%',
    marginTop: '30px',
  },
  typography: {
    marginBottom: '20px',
  },
})

class RegistrationCard extends React.Component {
  state = {
    email: '',
    password: '',
    passwordConfirm: '',
    namePrefix: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    selectedProviderType: {},
    providerTypes: [
      { name: 'Orvos', id: 1 },
      { name: 'Pszichológus', id: 2 },
    ],
    selectednamePrefix: {},
    namePrefixes: [
      { name: '', id: 0 },
      { name: 'dr', id: 1 },
      { name: 'Dr', id: 2 },
    ],
    status: '',
    errorMessage: '',
    errors: {},
  }

  async componentDidMount() {
    this.setState({
      selectedProviderType: this.state.providerTypes[0],
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleTypeSelected = async selectedProviderType => {
    this.setState({
      selectedProviderType,
    })
  }

  handleNamePrefixSelected = async selectednamePrefix => {
    this.setState({
      selectednamePrefix,
      namePrefix: selectednamePrefix.name,
    })
  }

  handleSnackClose = () => {
    this.setState({
      status: '',
    })
  }

  submitForm = async event => {
    const {
      email,
      password,
      passwordConfirm,
      namePrefix,
      firstName,
      lastName,
      phoneNumber,
      selectedProviderType,
    } = this.state
    const { error, onSubmit } = this.props

    event.preventDefault()

    if (password !== passwordConfirm) {
      this.setState({
        status: 'error',
        errorMessage: 'A két jelszó nem egyezik!',
      })
      return
    }

    if (!email || email.length < 4) {
      this.setState({
        errors: {
          ...this.state.errors,
          email: 'Az e-mail megadása kötelező',
        },
      })
      return
    }

    if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      this.setState({
        errors: {
          ...this.state.errors,
          email: 'Az e-mail cím formátuma nem megfelelő',
        },
      })
      this.setState({
        status: 'error',
        errorMessage: 'Az e-mail cím formátuma nem megfelelő',
      })
      return
    }

    if (!phoneNumber || phoneNumber.length < 4) {
      this.setState({
        errors: {
          ...this.state.errors,
          phoneNumber: 'A telefonszám megadása kötelező',
        },
      })
      return
    }

    if(!/^[+?0-9()\\\\-\\\\.\\\\s]+$/.test(phoneNumber)) {
      this.setState({
        errors: {
          ...this.state.errors,
          phoneNumber: 'A telefonszám formátuma nem megfelelő. Nemzetközi formátumban, vagy csak számokat használva add meg, szóközök és zárójelek nélkül (pl. +36701234567, 06701234567)',
        },
      })
      this.setState({
        status: 'error',
        errorMessage: 'A telefonszám formátuma nem megfelelő',
      })
      return
    }

    try {
      this.setState({
        status: 'submitting',
      })
      await onSubmit({
        email,
        password,
        userTypeId: 1,
        namePrefix: namePrefix ? namePrefix : '',
        firstName,
        lastName,
        phoneNumber,
        providerTypeId: selectedProviderType.id,
      })
      alert(
        'Köszönjük a regisztációt, kérjük jelentkezz be és véglegesítsd a regisztrációdat'
      )
      window.location.replace('/login')
    } catch (err) {
      this.setState({
        status: 'error',
        errorMessage: error,
      })
    }
  }

  render() {
    const { classes } = this.props
    const {
      providerTypes,
      namePrefixes,
      email,
      password,
      status,
      passwordConfirm,
      firstName,
      lastName,
      phoneNumber,
      selectedProviderType,
      selectednamePrefix,
      errorMessage,
    } = this.state
    return (
      <React.Fragment>
        <Grid className={classes.headerContainer} item xs={12}>
          <Typography className={classes.header} variant="title" gutterBottom>
            Regisztráció
          </Typography>
        </Grid>
        <Grid className={classes.welcomeTextContainer} item xs={12}>
          <Typography className={classes.typography} align="left">
            {'Kedves Kolléga!'}
          </Typography>
          <Typography className={classes.typography} align="left">
            {`Örülünk, hogy itt vagy! Ezen a felületen fogunk hivatalosan szerződni, ahol adataid és az általunk kért dokumentumok feltöltése mellett tudod elolvasni és elfogadni az előre feltöltött Általános Szerződési Feltételeinket.`}
          </Typography>
          <Typography className={classes.typography} align="left">
            {`Fontos, hogy az adatok feltöltése nem jelent automatikus konzultációs lehetőséget. A feltöltött adatokat és dokumentumokat szakmai csapatunk először megvizsgálja, majd ha mindent rendben talált, akkor jóváhagyja a szerződést/regisztrációt, amiről e-mail-ben értesítést küldünk számodra. További fontos kritérium a regisztráció jóváhagyásához a Best Practice felmérő tesztjének a teljesítése, tehát csak ezután lehet élesben pácienseket fogadni az applikációban`}
          </Typography>
          <Typography align="left">
            {`Sikeres munkát kívánunk,`} <br></br>
            {`A DokiApp csapata és Somogyi Tibor ügyvezető`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={this.submitForm} autoComplete="off">
            <Grid container direction="column">
              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  label="Email"
                  className={classes.textField}
                  value={email}
                  onChange={this.handleChange('email')}
                  margin="normal"
                  error={!!this.state.errors.email}
                  helperText={
                    this.state.errors.email && this.state.errors.email
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="password"
                  label="Jelszó"
                  className={classes.textField}
                  type="password"
                  margin="normal"
                  value={password}
                  onChange={this.handleChange('password')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="passwordConfirm"
                  label="Jelszó megerősítése"
                  className={classes.textField}
                  type="password"
                  margin="normal"
                  value={passwordConfirm}
                  onChange={this.handleChange('passwordConfirm')}
                />
              </Grid>
              <Grid item xs={12}>
                <TypeSelector
                  required
                  className={classes.providerTypeSelector}
                  title={'Válasszon professziót'}
                  types={providerTypes}
                  selectedType={selectedProviderType}
                  displayParam="name"
                  handleTypeSelected={this.handleTypeSelected}
                />
              </Grid>
              <Grid item xs={12}>
                <TypeSelector
                  required
                  className={classes.providerTypeSelector}
                  title={'Válasszon titulust'}
                  types={namePrefixes}
                  selectedType={selectednamePrefix}
                  displayParam="name"
                  handleTypeSelected={this.handleNamePrefixSelected}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="lastName"
                  label="Vezetéknév"
                  className={classes.textField}
                  value={lastName}
                  onChange={this.handleChange('lastName')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="firstName"
                  label="Keresztnév"
                  className={classes.textField}
                  value={firstName}
                  onChange={this.handleChange('firstName')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="phoneNumber"
                  label="Telefonszám"
                  className={classes.textField}
                  value={phoneNumber}
                  onChange={this.handleChange('phoneNumber')}
                  margin="normal"
                  error={!!this.state.errors.phoneNumber}
                  helperText={
                    this.state.errors.phoneNumber && this.state.errors.phoneNumber
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  className={classes.button}
                  href="/login"
                >
                  Vissza
                </Button>
                {status !== 'submitting' && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Küldés
                  </Button>
                )}
                {status === 'submitting' && (
                  <CircularProgress className={classes.progress} />
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status === 'error'}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              {`${errorMessage ? errorMessage : 'Hiba regisztráció során!'}`}
            </span>
          }
        ></Snackbar>
      </React.Fragment>
    )
  }
}

RegistrationCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegistrationCard)
