import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  button: {
    margin: 0,
    marginBottom: '20px',
    width: '100%',
  },
  editButton: {
    width: '100%',
  },
  btnContainer: {
    textAlign: 'right',
  },
  card: {
    boxShadow: '5px',
    margin: 5,
  },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    textAlign: 'left',
  },
  emailTypog: {
    textAlign: 'right',
  },
})

const FeedbackCardList = ({ feedbacks, classes }) => (
  <React.Fragment>
    {feedbacks.map(feedback => {
      return (
        <Card className={classes.card} key={feedback.id}>
          <CardContent>
            <div className={classes.header}>
              <Typography component="p">
                {`Kapcsolódó konzultáció: ${feedback.requestId}`}
              </Typography>
              <Typography className={classes.emailTypog} component="p">
                {`Beküldő email: ${feedback.email}`}
              </Typography>
            </div>
            <Typography className={classes.descriptionContainer} component="p">
              {`Leírás: ${feedback.description}`}
            </Typography>
          </CardContent>
        </Card>
      )
    })}
  </React.Fragment>
)

export default withStyles(styles)(FeedbackCardList)
