import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'

import { withStyles } from '@material-ui/core/styles'

import TypeSelector from '../common/TypeSelector'
import {
  TextField,
  Card,
  CircularProgress,
  Button,
  Checkbox,
} from '@material-ui/core'
import axios from 'axios'
import saveAs from 'file-saver'
import BestPracticeDocumentMap from "./BestPracticeDocumentMap";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  registrationCardContainer: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit * 3,
    '&:visited': {
      color: 'black',
    },
  },
  progress: {
    margin: theme.spacing.unit * 3,
  },
  headerContainer: {
    width: 'fit-content',
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
    padding: '5%',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  welcomeTextContainer: {
    margin: '25px',
  },
  header: {
    color: 'white',
  },
  providerTypeSelector: {
    width: '90%',
    marginTop: '30px',
  },
  typography: {
    marginBottom: '20px',
  },
})

class BestPracticeContent extends React.Component {
  state = {
    email: '',
    name: '',
    selectedProviderType: {},
    providerTypes: [
      { name: 'Orvos', id: 1 },
      { name: 'Pszichológus', id: 2 },
    ],
    status: '',
    errorMessage: '',
  }

  async componentDidMount() {
    this.setState({
      selectedProviderType: this.state.providerTypes[0],
    })
  }

  handleChange = name => event => {
    console.log(90, name, event.target.value)
    this.setState({
      [name]: event.target.value,
    })
  }

  handleTypeSelected = async selectedProviderType => {
    this.setState({
      selectedProviderType,
    })
  }

  handleNamePrefixSelected = async selectednamePrefix => {
    this.setState({
      selectednamePrefix,
      namePrefix: selectednamePrefix.name,
    })
  }

  handleSnackClose = () => {
    this.setState({
      status: '',
    })
  }

  submitForm = async event => {
    const {
      email,
      password,
      address,
      name,
      selectedProviderType,
    } = this.state
    const { error, onSubmit } = this.props

    event.preventDefault()


    try {
      this.setState({
        status: 'submitting',
      })
      console.log(this.state)
      const { data } = await axios.post(`/api/nda/create`, { name, email, address })
      if (data.id) {
        const bestPractice = BestPracticeDocumentMap[selectedProviderType.id];
        const fileBlob = await fetch(`/${encodeURIComponent(bestPractice)}`).then(res => res.blob())
        saveAs(fileBlob, bestPractice)
        this.setState({
          status: '',
        })
      }
      console.log(data)
    } catch (err) {
      this.setState({
        status: 'error',
        errorMessage: error,
      })
    }
  }

  render() {
    const { classes } = this.props
    const {
      address,
      name,
      email,
      status,
      providerTypes,
      selectedProviderType,
      errorMessage,
      acceptedPrivacyPolicy,
    } = this.state
    return (
      <React.Fragment>
        <Grid className={classes.headerContainer} item xs={12}>
          <Typography className={classes.header} variant="title" gutterBottom>
            Best practice
          </Typography>
        </Grid>
        <Grid className={classes.welcomeTextContainer} item xs={12}>
          <Typography className={classes.typography} align="left">
            {'Kedves Kolléga!'}
          </Typography>
          <Typography className={classes.typography} align="left">
            {`Ezúton küldjük Neked a DokiApp működési elveit tartalmazó Best Practice dokumentumot, amit minden szakembernek mélyrehatóan át kell tanulmányoznia, amiről tesztet és személyes konzultációt is fogunk tartani. A dokumentumot csak az adatok kitöltése és a titoktartási nyilatkozat elfogadása után tudod letölteni.`}
          </Typography>
          <Typography className={classes.typography} align="left">
            {`Kérdés esetén fordulj hozzánk bizalommal: hello@dokiapp.hu, +36704551501`}
          </Typography>
          <Typography align="left">
            {`Sikeres munkát kívánunk,`} <br></br>
            {`A DokiApp csapata és Somogyi Tibor ügyvezető`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={this.submitForm} autoComplete="off">
            <Grid container direction="column">
              <Grid item xs={12}>
                <TextField
                  required
                  id="name"
                  label="Név"
                  className={classes.textField}
                  value={name}
                  onChange={this.handleChange('name')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="name"
                  label="Lakcím"
                  className={classes.textField}
                  value={address}
                  onChange={this.handleChange('address')}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  label="Email"
                  className={classes.textField}
                  value={email}
                  onChange={this.handleChange('email')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TypeSelector
                  required
                  className={classes.providerTypeSelector}
                  title={'Válasszon professziót'}
                  types={providerTypes}
                  selectedType={selectedProviderType}
                  displayParam="name"
                  handleTypeSelected={this.handleTypeSelected}
                />
              </Grid>
              <Card className="row_privacy_policy">
                <Checkbox
                  onChange={(e, checked) =>
                    this.handleChange('acceptedPrivacyPolicy')({ target: { value: checked } })
                  }
                  checked={acceptedPrivacyPolicy || false}
                  inputProps={{ required: true }}
                />

                <div>
                  Jelen DokiApp Best Practice letöltésével elfogadom a{' '}
                  <a
                    href="/titoktartasi_dokiapp_best_practice.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    titoktartási nyilatkozatot!
                  </a>
                </div>
              </Card>
              <Grid item xs={12}>
                {status !== 'submitting' && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Letöltés
                  </Button>
                )}
                {status === 'submitting' && (
                  <CircularProgress className={classes.progress} />
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status === 'error'}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              {`${errorMessage ? errorMessage : 'Hiba regisztráció során!'}`}
            </span>
          }
        ></Snackbar>
      </React.Fragment>
    )
  }
}

BestPracticeContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BestPracticeContent)
