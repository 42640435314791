import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import ListIcon from '@material-ui/icons/List'

const styles = theme => ({
  button: {
    margin: 0,
    marginBottom: '20px',
    width: '100%',
  },
  editButton: {
    width: '100%',
  },
  btnContainer: {
    textAlign: 'right',
  },
  selectContainer: {
    alignItems: 'center',
  },
  card: {
    boxShadow: 'none',
  },
})

const CallHistoryCardList = ({
  calls,
  classes,
  handleShowCallExtraInfo,
  formatDate,
  formatCallDuration,
  userType,
}) => (
  <React.Fragment>
    {calls.map(call => {
      return (
        <Card className={classes.card} key={call.id}>
          <CardContent>
            <Typography component="p">{`ID: ${call.id}`}</Typography>
            <Typography component="p">
              {`Számlaszám: ${call.transactionId}`}
            </Typography>
            <Typography component="p">
              {`Szakterület: ${
                call.providerTypeId === 1 ? 'Orvos' : 'Pszichológus'
              }`}
            </Typography>
            <Typography component="p">
              {`Orvos neve: ${call.providerName}`}
            </Typography>
            <Typography component="p">
              {`Orvos email: ${call.providerEmail}`}
            </Typography>
            <Typography component="p">
              {`Páciens neve: ${call.userName}`}
            </Typography>
            <Typography component="p">
              {`Páciens email: ${call.userEmail}`}
            </Typography>
            <Typography component="p">
              {`Hívás időtartama: ${formatCallDuration(call.callSeconds)}`}
            </Typography>
            <Typography component="p">
              {`Hívás kezdete: ${formatDate(call.callStartedAt)}`}
            </Typography>
            <Typography component="p">
              {`Hívás vége: ${formatDate(call.callEndedAt)}`}
            </Typography>
          </CardContent>
          {userType == 'supervisorDoctor' && (
            <CardActions>
              <IconButton
                onClick={() => {
                  handleShowCallExtraInfo(call)
                }}
                className={classes.button}
                aria-label="Show info"
              >
                <ListIcon />
              </IconButton>
            </CardActions>
          )}
        </Card>
      )
    })}
  </React.Fragment>
)

export default withStyles(styles)(CallHistoryCardList)
