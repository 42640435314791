import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Main from '../main/Main'
import HeaderContainer from '../../containers/header/HeaderContainer'
import SideMenu from '../sideMenu/SideMenu'
import decodeUserType from '../../utils/jwt-decode'

const styles = theme => ({
  container: {
    background: '#e1e8f0',
    height: '100%',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    marginTop: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    overflow: 'auto',
    maxHeight: '80vh',
  },
})

class Dasboard extends Component {
  render() {
    if (this.props.userType === 'doctor') {
      return <Main />
    } else {
      return (
        <Grid className={this.props.classes.container}>
          <Grid item xs={12}>
            <HeaderContainer />
          </Grid>
          <Grid container spacing={16}>
            {this.props.userType !== 'doctor' && (
              <Grid item xs={2}>
                <SideMenu />
              </Grid>
            )}
            <Grid item xs={10}>
              <Paper className={this.props.classes.paper}>
                {' '}
                <Main />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }
}

const mapStateToProps = ({ user }) => ({
  userType: decodeUserType(user.token),
})

export default connect(mapStateToProps, {})(withStyles(styles)(Dasboard))
