export default function formatDate(dateString) {
  const date = new Date(dateString)
  const month = new String(date.getMonth() + 1).padStart(2, '0')
  const day = new String(date.getDate()).padStart(2, '0')
  const hours = new String(date.getHours()).padStart(2, '0')
  const minutes = new String(date.getMinutes()).padStart(2, '0')
  const seconds = new String(date.getSeconds()).padStart(2, '0')

  return `${date.getFullYear()}.${month}.${day} ${hours}:${minutes}:${seconds}`
}
