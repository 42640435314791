import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  formContainer: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit,
  },
  headerContainer: {
    position: 'relative',
    top: '-16px',
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  headerText: {
    color: 'white',
    padding: '10px',
  },
})

class PatientForm extends React.Component {
  state = {
    email: '',
    password: '',
    name: '',
    id: null,
    init: false,
    status: '',
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  submitForm = async event => {
    const payload = this.state
    const formattedPayload = this.formatPayload(payload)
    const { onSubmit } = this.props
    event.preventDefault()

    try {
      this.setState({
        status: 'submitting',
      })
      await onSubmit(formattedPayload)
    } catch (err) {
      this.setState({
        status: 'error',
      })
    }
  }

  formatPayload = payload => {
    const formatted = payload
    if (!payload.birthday) {
      formatted.birthday = null
    }
    return formatted
  }

  handleSnackClose = () => {
    this.setState({
      status: '',
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { patient } = props
    const { init } = state

    if (init) {
      return state
    }

    return {
      email: patient.email || '',
      password: patient.password || '',
      name: patient.name || '',
      birthday: patient.birthday || '',
      id: patient.id || null,
      init: true,
    }
  }

  render() {
    const { classes, onBackClick } = this.props
    const { email, birthday, password, name, id, status } = this.state
    return (
      <React.Fragment>
        <Grid container className={classes.formContainer} direction="column">
          <Grid className={classes.headerContainer} item xs={12}>
            <Typography
              className={classes.headerText}
              variant="title"
              gutterBottom
            >
              {id && <span>Páciens szerkesztés</span>}
              {!id && <span>Páciens felvétel</span>}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.submitForm} autoComplete="off">
              <Grid container direction="column">
                <Grid item xs={12}>
                  <TextField
                    required
                    id="name"
                    label="Név"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange('name')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="email"
                    label="Email"
                    className={classes.textField}
                    value={email}
                    onChange={this.handleChange('email')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="birthday"
                    label="Születési dátum"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={birthday || ''}
                    type="date"
                    onChange={this.handleChange('birthday')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required={!id}
                    id="password"
                    label={!id ? 'Jelszó' : 'Új jelszó'}
                    className={classes.textField}
                    type="password"
                    margin="normal"
                    value={password}
                    onChange={this.handleChange('password')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {status !== 'submitting' && (
                    <React.Fragment>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Mentés
                      </Button>
                      <Button
                        onClick={onBackClick}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Vissza
                      </Button>
                    </React.Fragment>
                  )}
                  {status === 'submitting' && (
                    <CircularProgress className={classes.progress} />
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status === 'error'}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hiba a mentés során!</span>}
        ></Snackbar>
      </React.Fragment>
    )
  }
}

PatientForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PatientForm)
