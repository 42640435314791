import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import validateToken from '../../utils/jwt-helper'
import decodeUserType from '../../utils/jwt-decode'

const CheckedRoute = ({
  component: Component,
  needsAuth,
  isLoggedIn,
  redirect,
  userType,
  ...rest
}) => (
  <Route
    {...rest}
    render={() => {
      if (
        isLoggedIn &&
        userType !== 'doctor' &&
        window.location.href.indexOf('/dashboard/profile') !== -1
      ) {
        return <Redirect to="/" />
      }

      if (
        isLoggedIn &&
        userType === 'doctor' &&
        window.location.href.indexOf('/dashboard/profile') === -1
      ) {
        return <Redirect to="/dashboard/profile" />
      }

      if (needsAuth && !isLoggedIn) {
        return <Redirect to="/login" />
      }

      if (!needsAuth && isLoggedIn) {
        return <Redirect to="/" />
      }

      return <Component />
    }}
  />
)

export default connect(({ user }) => ({
  isLoggedIn: validateToken(user.token),
  userType: decodeUserType(user.token),
}))(CheckedRoute)
