import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import InputLabel from '@material-ui/core/InputLabel'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

import { withStyles } from '@material-ui/core/styles'
import { FormControl } from '../../../node_modules/@material-ui/core'

import DownloadLink from './DownloadLink'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  checkboxField: {
    width: '90%',
    margin: '0 auto',
    padding: '10px',
    display: 'flex',
    'align-items': 'center',
  },
  formContainer: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit,
  },
  headerContainer: {
    position: 'relative',
    top: '-16px',
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  headerText: {
    color: 'white',
    padding: '10px',
  },
  selectContainer: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    justifyContent: 'flex-start',
    width: '90%',
  },
  documentsContainer: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: '16px',
    width: '90%',
    overflow: 'auto',
  },
})

const prefixOptions = [
  { display: 'Nincs', value: '' },
  { display: 'dr.', value: 'dr.' },
  { display: 'Dr.', value: 'Dr.' },
  { display: 'Prof.', value: 'Prof.' },
]

class DoctorForm extends React.Component {
  state = {
    email: '',
    password: '',
    namePrefix: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    validated: true,
    id: null,
    init: false,
    status: '',
    providerTypeId: 1,
    chamberMembershipNumber: null,
    acceptedPrivacyPolicy: false,
    profile: {},
    languages: [],
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handlePrivacyPolicyChange = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  submitForm = async event => {
    const payload = this.state
    const formattedPayload = this.formatPayload(payload)
    const { onSubmit } = this.props
    event.preventDefault()

    try {
      this.setState({
        status: 'submitting',
      })
      await onSubmit(formattedPayload)
    } catch (err) {
      this.setState({
        status: 'error',
      })
    }
  }

  formatPayload = payload => {
    const formatted = payload
    if (!payload.birthday) {
      formatted.birthday = null
    }
    return formatted
  }

  handleSnackClose = () => {
    this.setState({
      status: '',
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { doctor, selectedProviderType, profile } = props
    const { init } = state

    if (init) {
      return state
    }

    return {
      email: doctor.email || '',
      password: doctor.password || '',
      namePrefix: doctor.namePrefix || '',
      firstName: doctor.firstName || '',
      lastName: doctor.lastName || '',
      phoneNumber: doctor.phoneNumber || '',
      birthday: doctor.birthday || '',
      validated: doctor.validated || false,
      id: doctor.id || null,
      chamberMembershipNumber: profile.chamberMembershipNumber || null,
      acceptedPrivacyPolicy: profile.acceptedPrivacyPolicy || false,
      ...(() => {
        return doctor && doctor.providerTypes
          ? { providerTypeId: doctor.providerTypes[0] }
          : { providerTypeId: selectedProviderType.id }
      })(),
      init: true,
    }
  }

  render() {
    const {
      profile: {
        medicalDegreeUrl,
        specialistCertificateUrl,
        insuranceUrl,
        identityCardFrontUrl,
        identityCardBackUrl,
        aeekCardFrontUrl,
        aeekCardBackUrl,
        homeAddressCardUrl,
        accountNumber,
        billingData,
        languages,
      },
      classes,
      onBackClick,
      providerTypes,
    } = this.props
    const {
      email,
      password,
      birthday,
      namePrefix,
      firstName,
      lastName,
      phoneNumber,
      id,
      status,
      providerTypeId,
      chamberMembershipNumber,
      acceptedPrivacyPolicy,
    } = this.state
    console.log('doctor form', languages)
    return (
      <React.Fragment>
        <Grid container className={classes.formContainer} direction="column">
          <Grid className={classes.headerContainer} item xs={12}>
            <Typography
              className={classes.headerText}
              variant="title"
              gutterBottom
            >
              {id && <span>Orvos szerkesztés</span>}
              {!id && <span>Orvos felvétel</span>}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.submitForm} autoComplete="off">
              <Grid container direction="column">
                {!id && (
                  <Grid item xs={12}>
                    <FormControl className={classes.selectContainer}>
                      <InputLabel>Típus</InputLabel>
                      <Select
                        id="providerType"
                        value={providerTypeId}
                        placeholder="Típus"
                        style={{ textAlign: 'left' }}
                        onChange={this.handleChange('providerTypeId')}
                      >
                        {providerTypes.map(providerType => (
                          <MenuItem
                            key={providerType.id}
                            value={providerType.id}
                          >
                            {providerType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {id && (
                  <Grid className={classes.documentsContainer} item xs={12}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {providerTypeId === 1
                              ? 'Általános orvosi diploma'
                              : 'Pszichológus diploma'}
                          </TableCell>
                          <TableCell>
                            {providerTypeId === 1
                              ? 'Szakorvosi végzettség'
                              : 'Szakpszichológusi diploma'}
                          </TableCell>
                          {providerTypeId === 1 ? (
                            <React.Fragment>
                              <TableCell>ÁEEK kártya elől</TableCell>
                              <TableCell>ÁEEK kártya hátul</TableCell>
                              <TableCell>Felelősség biztosítás</TableCell>
                            </React.Fragment>
                          ) : (
                            <TableCell>Lakcímkártya</TableCell>
                          )}
                          <TableCell>Személyi igazolvány elől</TableCell>
                          <TableCell>Személyi igazolvány hátul</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <DownloadLink
                              userId={id}
                              downloadLink={medicalDegreeUrl}
                            />
                          </TableCell>
                          <TableCell>
                            <DownloadLink
                              userId={id}
                              downloadLink={specialistCertificateUrl}
                            />
                          </TableCell>
                          {providerTypeId === 1 ? (
                            <React.Fragment>
                              <TableCell>
                                <DownloadLink
                                  userId={id}
                                  downloadLink={aeekCardFrontUrl}
                                />
                              </TableCell>
                              <TableCell>
                                <DownloadLink
                                  userId={id}
                                  downloadLink={aeekCardBackUrl}
                                />
                              </TableCell>
                              <TableCell>
                                <DownloadLink
                                  userId={id}
                                  downloadLink={insuranceUrl}
                                />
                              </TableCell>
                            </React.Fragment>
                          ) : (
                            <TableCell>
                              <DownloadLink
                                userId={id}
                                downloadLink={homeAddressCardUrl}
                              />
                            </TableCell>
                          )}
                          <TableCell>
                            <DownloadLink
                              userId={id}
                              downloadLink={identityCardFrontUrl}
                            />
                          </TableCell>
                          <TableCell>
                            <DownloadLink
                              userId={id}
                              downloadLink={identityCardBackUrl}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                {id && (
                  <Grid className={classes.documentsContainer} item xs={12}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Cégnév</TableCell>
                          <TableCell>Ország</TableCell>
                          <TableCell>Irányítószám</TableCell>
                          <TableCell>Város</TableCell>
                          <TableCell>Cím</TableCell>
                          <TableCell>Emelet, ajtó, stb</TableCell>
                          <TableCell>Adószám</TableCell>
                          <TableCell>Számlaszám</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {billingData ? billingData.id : 'Nem elérhető'}
                          </TableCell>
                          <TableCell>
                            {billingData
                              ? billingData.userGivenName
                              : 'Nem elérhető'}
                          </TableCell>
                          <TableCell>
                            {billingData
                              ? billingData.countryName
                              : 'Nem elérhető'}
                          </TableCell>
                          <TableCell>
                            {billingData
                              ? billingData.postalCode
                              : 'Nem elérhető'}
                          </TableCell>
                          <TableCell>
                            {billingData ? billingData.city : 'Nem elérhető'}
                          </TableCell>
                          <TableCell>
                            {billingData
                              ? billingData.streetAddress
                              : 'Nem elérhető'}
                          </TableCell>
                          <TableCell>
                            {billingData ? billingData.misc : 'Nem elérhető'}
                          </TableCell>
                          <TableCell>
                            {billingData
                              ? billingData.vatNumber
                              : 'Nem elérhető'}
                          </TableCell>
                          <TableCell>
                            {accountNumber || 'Nem elérhető'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl className={classes.selectContainer}>
                    <InputLabel>Titulus</InputLabel>
                    <Select
                      id="namePrefix"
                      value={namePrefix}
                      placeholder="Titulus"
                      style={{ textAlign: 'left' }}
                      onChange={this.handleChange('namePrefix')}
                    >
                      {prefixOptions.map(prefix => (
                        <MenuItem key={prefix.value} value={prefix.value}>
                          {prefix.display}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="firstName"
                    label="Keresztnév"
                    className={classes.textField}
                    value={firstName}
                    onChange={this.handleChange('firstName')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="lastName"
                    label="Vezetéknév"
                    className={classes.textField}
                    value={lastName}
                    onChange={this.handleChange('lastName')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="email"
                    label="Email"
                    className={classes.textField}
                    value={email}
                    onChange={this.handleChange('email')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="phoneNumber"
                    label="Telefonszám"
                    className={classes.textField}
                    margin="normal"
                    value={phoneNumber}
                    onChange={this.handleChange('phoneNumber')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="birthday"
                    label="Születési dátum"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={birthday || ''}
                    type="date"
                    onChange={this.handleChange('birthday')}
                    margin="normal"
                  />
                </Grid>
                {providerTypeId === 1 && (
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="chamberMembershipNumber"
                      label="Nyílvántartási szám"
                      className={classes.textField}
                      value={chamberMembershipNumber || ''}
                      onChange={this.handleChange('chamberMembershipNumber')}
                      margin="normal"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    required={!id}
                    id="password"
                    label={!id ? 'Jelszó' : 'Új jelszó'}
                    className={classes.textField}
                    type="password"
                    margin="normal"
                    value={password}
                    onChange={this.handleChange('password')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.checkboxField}>
                    <Checkbox
                      onChange={(e, checked) =>
                        this.handlePrivacyPolicyChange(
                          'acceptedPrivacyPolicy',
                          checked
                        )
                      }
                      checked={acceptedPrivacyPolicy || false}
                    />
                    <div>ÁSZF elfogadva</div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {status !== 'submitting' && (
                    <React.Fragment>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Mentés
                      </Button>
                      <Button
                        onClick={onBackClick}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Vissza
                      </Button>
                    </React.Fragment>
                  )}
                  {status === 'submitting' && (
                    <CircularProgress className={classes.progress} />
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status === 'error'}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hiba a mentés során!</span>}
        ></Snackbar>
      </React.Fragment>
    )
  }
}

DoctorForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DoctorForm)
