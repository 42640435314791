import axios from 'axios'

export default function(store) {
  axios.interceptors.request.use(config => ({
    ...config,
    baseURL: '/', // eslint-disable-line
    headers: {
      ...config.headers,
      Authorization: `Bearer ${store.getState().user.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }))

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 401) {
        store.dispatch({
          type: 'LOGOUT_USER',
        })
        throw error
      }
      if (error.response.status === 400) {
        throw error
      }
    }
  )
}
