/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Chip, Input, MenuItem, Select } from '@material-ui/core'
import './LanguageChips.css'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const supportedLanguages = [
  { locale: 'hu', displayAs: 'Magyar' },
  { locale: 'en', displayAs: 'Angol' },
  { locale: 'ua', displayAs: 'Ukrán' },
]

const translateLocale = locale => {
  const language = supportedLanguages.find(
    l => l.locale.toLowerCase() === locale.toLowerCase()
  )
  if (language) return language.displayAs

  return `No locale matches '${locale}'`
}

const getStyles = (language, languages) => {
  return languages.indexOf(language) > -1 ? 'selected-language' : ''
}

const LanguageChips = ({
  onChange,
  languages,
  label = 'Multi Select Chip',
}) => {
  const [spokenLanguages, setSpokenLanguages] = useState([])

  const handleChange = evt => {
    console.log('languages changed', evt, evt.target.value)
    const selectedSpokenLanguages = evt.target.value
    setSpokenLanguages(selectedSpokenLanguages)
    if (onChange instanceof Function) {
      onChange('languages', selectedSpokenLanguages)
    }
  }

  useEffect(() => {
    setSpokenLanguages(languages)
  }, [languages])

  if (!Array.isArray(languages)) {
    console.error('languages must be an array')
    return 'Invalid input: languages must be an array'
  }

  return (
    <>
      <Card className="row">
        <div className="description">
          <p>
            <b>{label}</b>
          </p>

          <Select
            //labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={languages}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
              <div>
                {Array.isArray(selected) &&
                  selected.map(v => (
                    <Chip key={v} label={translateLocale(v)} />
                  ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {supportedLanguages.map(l => (
              <MenuItem
                key={l.locale}
                value={l.locale}
                className={getStyles(l.locale, languages)}
              >
                {l.displayAs}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Card>
    </>
  )
}

LanguageChips.displayName = 'LanguageChips'

LanguageChips.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
}

LanguageChips.defaultProps = {}

export default LanguageChips
export { LanguageChips }
