import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'

import {
  getCountries,
  createCountry,
  updateCountry,
  deleteCountry,
} from '../../modules/admin'

import CountryTable from '../../components/country/CountryTable'
import CountryForm from '../../components/country/CountryForm'
import CountryCardList from '../../components/country/CountryCardList'

import confirmDelete from '../../utils/confirmDelete'

class CountryContainer extends React.Component {
  state = {
    showCountryForm: false,
    selectedCountry: {},
  }
  componentDidMount() {
    this.props.getCountries()
  }

  showForm = () => {
    this.setState({
      showCountryForm: true,
    })
  }

  handleEdit = country => {
    this.setState({
      selectedCountry: country,
      showCountryForm: true,
    })
  }

  submitForm = async payload => {
    if (!payload.id) {
      await this.props.createCountry({ ...payload })
    } else {
      await this.props.updateCountry({ ...payload })
    }
    this.props.getCountries()
    this.setState({
      showCountryForm: false,
    })
  }

  handleDelete = async ({ id }) => {
    const canDelete = await confirmDelete(
      this.props.countries,
      id,
      'countryName'
    )
    if (canDelete) {
      await this.props.deleteCountry({ id })
      await this.props.getCountries()
    }
  }

  handleSearch = async searchText => {
    await this.props.getCountries(searchText)
  }

  handleBack = () => {
    this.setState({
      showCountryForm: false,
      selectedCountry: {},
    })
  }

  render() {
    const { countries } = this.props
    const { showCountryForm, selectedCountry } = this.state
    return (
      <React.Fragment>
        {!showCountryForm && (
          <React.Fragment>
            <Hidden only={['sm', 'xs']}>
              <CountryTable
                handleAdd={this.showForm}
                handleSearch={this.handleSearch}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                countries={countries}
              />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <CountryCardList
                handleAdd={this.showForm}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                countries={countries}
              />
            </Hidden>
          </React.Fragment>
        )}
        {showCountryForm && (
          <CountryForm
            country={selectedCountry}
            onBackClick={this.handleBack}
            onSubmit={this.submitForm}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  countries: admin.countries,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountries,
      createCountry,
      updateCountry,
      deleteCountry,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CountryContainer)
