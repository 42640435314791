import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'
import StatusCard from '../../components/status/StatusCard'
import WaitingTimeTable from '../../components/status/WaitingTimeTable'
import WaitingTimeCardList from '../../components/status/WaitingTimeCardList'

import {
  getActiveRequestCount,
  getAvailableProvidersCount,
  getAvailableRequestsCount,
  getAverageWaitingTimes,
  getProviderTypes,
} from '../../modules/admin'
import { ProviderPresence } from './ProviderPresence'

class StatusContainer extends React.Component {
  state = {
    selectedProviderType: {},
  }

  async componentDidMount () {
    await this.props.getProviderTypes()
    await this.props.getAvailableRequestsCount()
    await this.props.getAvailableProvidersCount()
    await this.props.getActiveRequestCount()
    this.setState(
      {
        selectedProviderType: this.props.providerTypes[0],
      },
      () => {
        this.props.getAverageWaitingTimes(this.state.selectedProviderType.id)
      }
    )

    this.autoRefreshWaitingTimes()
    this.autoRefreshStats()
  }

  autoRefreshWaitingTimes = () => {
    this.waitingTimesInterval = setInterval(async () => {
      this.props.getAverageWaitingTimes(this.state.selectedProviderType.id)
    }, 15000)
  }

  autoRefreshStats = () => {
    this.statsRefreshInterval = setInterval(async () => {
      this.props.getAvailableRequestsCount()
      this.props.getAvailableProvidersCount()
      this.props.getActiveRequestCount()
    }, 5000)
  }

  handleTypeSelected = async selectedProviderType => {
    this.setState(
      {
        selectedProviderType,
      },
      () => {
        this.props.getAverageWaitingTimes(this.state.selectedProviderType.id)
      }
    )
  }

  componentWillUnmount () {
    clearInterval(this.waitingTimesInterval)
    clearInterval(this.statsRefreshInterval)
  }

  render () {
    const {
      averageWaitingTimes: {
        waitingTimes,
        averageElementCount,
        averageWaitingTime,
      },
      availableProvidersCount,
      availableRequestsCount,
      activeRequestsCount,
      providerTypes,
    } = this.props
    const { selectedProviderType } = this.state
    return (
      <React.Fragment>
        <StatusCard
          label='Bejelentkezett orvosok'
          count={availableProvidersCount}
        ></StatusCard>
        <StatusCard
          label='Várakozó páciensek'
          count={availableRequestsCount}
        ></StatusCard>
        {/* <StatusCard
          label='Aktív callban lévő páciensek'
          count={activeRequestsCount}
        ></StatusCard>
        <StatusCard
          label='Aktív callban lévő orvosok'
          count={activeRequestsCount}
        ></StatusCard> */}
        <ProviderPresence/>
        <Hidden only={['sm', 'xs']}>
          <WaitingTimeTable
            waitingTimes={waitingTimes}
            handleTypeSelected={this.handleTypeSelected}
            providerTypes={providerTypes}
            selectedProviderType={selectedProviderType}
          ></WaitingTimeTable>
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <WaitingTimeCardList
            waitingTimes={waitingTimes}
            handleTypeSelected={this.handleTypeSelected}
            providerTypes={providerTypes}
            selectedProviderType={selectedProviderType}
          ></WaitingTimeCardList>
        </Hidden>
        <StatusCard
          label='Átlag várakozó páciensek'
          count={averageElementCount}
        ></StatusCard>
        <StatusCard
          label='Átlag várakozási idő'
          count={averageWaitingTime}
        ></StatusCard>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  availableProvidersCount: admin.availableProvidersCount,
  availableRequestsCount: admin.availableRequestsCount,
  averageWaitingTimes: admin.averageWaitingTimes,
  activeRequestsCount: admin.activeRequestsCount,
  providerTypes: admin.providerTypes,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getActiveRequestCount,
      getAvailableProvidersCount,
      getAvailableRequestsCount,
      getAverageWaitingTimes,
      getProviderTypes,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(StatusContainer)
