import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'

import { getTips, createTip, updateTip, deleteTip } from '../../modules/admin'

import TipTable from '../../components/tip/TipTable'
import TipForm from '../../components/tip/TipForm'
import TipCardList from '../../components/tip/TipCardList'

import confirmDelete from '../../utils/confirmDelete'

class TipContainer extends React.Component {
  state = {
    showTipForm: false,
    selectedTip: {},
  }
  componentDidMount() {
    this.props.getTips()
  }

  showForm = () => {
    this.setState({
      showTipForm: true,
    })
  }

  handleEdit = Tip => {
    this.setState({
      selectedTip: Tip,
      showTipForm: true,
    })
  }

  submitForm = async payload => {
    if (!payload.id) {
      await this.props.createTip({ ...payload })
    } else {
      await this.props.updateTip({ ...payload })
    }
    this.props.getTips()
    this.setState({
      showTipForm: false,
    })
  }

  handleDelete = async ({ id }) => {
    const canDelete = await confirmDelete(this.props.tips, id, 'title')
    if (canDelete) {
      await this.props.deleteTip({ id })
      await this.props.getTips()
    }
  }

  handleSearch = async searchText => {
    await this.props.getTips(searchText)
  }

  handleBack = () => {
    this.setState({
      showTipForm: false,
      selectedTip: {},
    })
  }

  render() {
    const { tips } = this.props
    const { showTipForm, selectedTip } = this.state
    return (
      <React.Fragment>
        {!showTipForm && (
          <React.Fragment>
            <Hidden only={['sm', 'xs']}>
              <TipTable
                handleAdd={this.showForm}
                handleSearch={this.handleSearch}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                tips={tips}
              />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <TipCardList
                handleAdd={this.showForm}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                tips={tips}
              />
            </Hidden>
          </React.Fragment>
        )}
        {showTipForm && (
          <TipForm
            tip={selectedTip}
            onBackClick={this.handleBack}
            onSubmit={this.submitForm}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  tips: admin.tips,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTips,
      createTip,
      updateTip,
      deleteTip,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TipContainer)
