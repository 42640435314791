import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Switch, withRouter, Redirect } from 'react-router-dom'
import CheckedRoute from './components/common/CheckedRoute'
import Dashboard from './components/dashboard/Dashboard'
import LoginContainer from './containers/login/LoginContainer'
import RegistrationContainer from './containers/registration/RegistrationContainer'
import GitInfo from 'react-git-info/macro';
import BestPracticeContainer from './containers/bestPractice/BestPracticeContainer'
import './i18n'

const debug = require('debug')('dokiapp')

const gitInfo = GitInfo();
debug('GIT INFO', gitInfo)

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    height: '100vh',
  },
})

class App extends Component {
  render() {
    const classes =
      window.location.href.indexOf('/dashboard/profile') !== -1
        ? ''
        : this.props.classes.root
    return (
      <div className={classes}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <CheckedRoute
            path="/login"
            needsAuth={false}
            component={withRouter(LoginContainer)}
            exact
          />
          <CheckedRoute
            path="/register"
            needsAuth={false}
            component={withRouter(RegistrationContainer)}
            exact
          />
          <CheckedRoute
            path="/best-practice"
            needsAuth={false}
            component={withRouter(BestPracticeContainer)}
            exact
          />
          <CheckedRoute
            path="/dashboard"
            needsAuth={true}
            component={withRouter(Dashboard)}
          />
        </Switch>
      </div>
    )
  }
}

export default withStyles(styles)(App)
