import axios from 'axios'

export const STORE_USER = 'STORE_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const AUTH_USER = 'AUTH_USER'
export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'

const initialState = {
  token: null,
  isAuthenticating: false,
  authError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        isAuthenticating: false,
        authError: '',
        token: action.payload.token,
      }
    case AUTH_IN_PROGRESS:
      return {
        ...state,
        isAuthenticating: true,
      }
    case AUTH_ERROR:
      return {
        ...state,
        authError: action.payload.message,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        authError: '',
      }
    case REGISTER_ERROR:
      return {
        ...state,
        authError: action.payload.message,
      }
    case LOGOUT_USER:
      return initialState
    default:
      return state
  }
}

export const loginUser = ({ email, password }) => async dispatch => {
  try {
    console.log("loginuser")
    dispatch({ type: AUTH_IN_PROGRESS })
    const {
      data: { token },
    } = await axios.post('/api/platform/auth/admin/login', { email, password })
    dispatch({ type: AUTH_USER, payload: { token } })
  } catch (err) {
    console.log(err)
    dispatch({
      type: AUTH_ERROR,
      payload: { message: 'Hiba a bejelentkezés során.' },
    })
    throw err
  }
}

export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER })
}

export const registerUser = payload => async dispatch => {
  try {
    await axios.post('/api/platform/auth/register', payload)
    dispatch({ type: REGISTER_SUCCESS })
  } catch (err) {
    const { response: { data, status } } = err;
    console.log(`status: ${status}, message: ${data.message}`)
    dispatch({
      type: REGISTER_ERROR,
      payload: { message: 'Hiba a regisztráció során.'},
    })
    throw err
  }
}
