import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'

import Search from '../common/Search'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  btnContainer: {
    textAlign: 'right',
  },
  searchContainer: {
    textAlign: 'left',
  },
})

const FeedbackTable = ({ feedbacks, classes, handleSearch }) => (
  <React.Fragment>
    <Grid container justify="flex-start">
      <Grid className={classes.searchContainer} item md={8}>
        <Search handleSearch={handleSearch} />
      </Grid>
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Kapcsolódó konzultáció</TableCell>
          <TableCell>Leírás</TableCell>
          <TableCell>Beküldő email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {feedbacks.map(feedback => {
          return (
            <TableRow key={feedback.id}>
              <TableCell>{feedback.requestId}</TableCell>
              <TableCell>{feedback.description}</TableCell>
              <TableCell>{feedback.email}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

export default withStyles(styles)(FeedbackTable)
