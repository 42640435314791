import React from 'react'
import { Card, CircularProgress } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'

import './Upload.css'
import { useTranslation } from 'react-i18next'

const Upload = ({
  name,
  downloadLink,
  onSelect,
  fileName,
  isUploading,
  download,
}) => {
  const { t } = useTranslation('upload')
  return (
    <Card className="row">
      <div className="description">
        <p>
          <b>{name}</b>
        </p>
        {downloadLink ? (
          <button
            type="button"
            className="download_button"
            onClick={() => download(downloadLink)}
          >
            { t('download')}
          </button>
        ) : (
          <p>{t('notAvailable')}</p>
        )}
      </div>
      <div className="file_upload">
        {isUploading ? (
          <CircularProgress />
        ) : (
          <React.Fragment>
            <label className="upload_button" htmlFor={`${fileName}_file_upload`}>
              {t('selectFile')}
            </label>
            {downloadLink ? (
              <React.Fragment>
                <p>{t('uploadSuccessful')}</p>
                <DoneIcon className="tick_icon" />
              </React.Fragment>
            ) : (
              <p>{t('noFileSelected')}</p>
            )}
            <input
              id={`${fileName}_file_upload`}
              className="hidden"
              type="file"
              onChange={e => onSelect(e.target.files[0], fileName)}
              accept=".png,.pdf,.jpg,.jpeg,.docx,.doc"
            />
          </React.Fragment>
        )}
      </div>
    </Card>
  )
}

export default Upload
