import axios from 'axios'

export const GET_DOCTORS_SUCCESS = 'GET_DOCTOR_SUCCESS'
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS'
export const GET_SYMPTOMS_SUCCESS = 'GET_SYMPTOMS_SUCCESS'
export const GET_TIPS_SUCCESS = 'GET_TIPS_SUCCESS'
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS'
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS'
export const GET_PROVIDER_TYPES_SUCCESS = 'GET_PROVIDER_TYPES_SUCCESS'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_AVAILABLE_PROVIDERS_COUNT_SUCCESS =
  'GET_AVAILABLE_PROVIDERS_COUNT_SUCCESS'
export const GET_AVAILABLE_REQUESTS_COUNT_SUCCESS =
  'GET_AVAILABLE_REQUESTS_COUNT_SUCCESS'
export const GET_AVERAGE_WAITING_TIMES_SUCCESS =
  'GET_AVERAGE_WAITING_TIMES_SUCCESS'
export const GET_CALL_HISTORY_SUCCESS = 'GET_CALL_HISTORY_SUCCESS'
export const GET_CALL_EXTRA_SUCCESS = 'GET_CALL_EXTRA_SUCCESS'
export const GET_PROVIDER_PROFILE_BY_ID_SUCCESS =
  'GET_PROVIDER_PROFILE_BY_ID_SUCCESS'
export const GET_PROVIDER_PROFILES_SUCCESS = 'GET_PROVIDER_PROFILES_SUCCESS'
export const GET_NDA_SUCCESS = 'GET_NDA_SUCCESS'
export const GET_ACTIVE_REQUEST_COUNT_SUCCESSS =
  'GET_ACTIVE_REQUEST_COUNT_SUCCESSS'
export const GET_REQUESTS_WITH_COUPONS_SUCCESS =
  'GET_REQUESTS_WITH_COUPONS_SUCCESS'

const initialState = {
  doctors: [],
  patients: [],
  symptoms: [],
  tips: [],
  feedbacks: [],
  addresses: [],
  providerTypes: [],
  countries: [],
  availableProvidersCount: 0,
  availableRequestsCount: 0,
  activeRequestsCount: 0,
  averageWaitingTimes: {
    waitingTimes: [],
    averageElementCount: null,
    averageWaitingTime: null,
  },
  callHistories: [],
  callExtraInfo: [],
  profile: {},
  profiles: [],
  nda: [],
  requestsWithCoupons: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: action.payload,
      }
      break
    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.payload,
      }
      break
    case GET_SYMPTOMS_SUCCESS:
      return {
        ...state,
        symptoms: action.payload,
      }
      break
    case GET_TIPS_SUCCESS:
      return {
        ...state,
        tips: action.payload,
      }
      break
    case GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        feedbacks: action.payload,
      }
      break
    case GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      }
      break
    case GET_PROVIDER_TYPES_SUCCESS:
      return {
        ...state,
        providerTypes: action.payload,
      }
      break
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      }
      break
    case GET_AVAILABLE_PROVIDERS_COUNT_SUCCESS:
      return {
        ...state,
        availableProvidersCount: action.payload,
      }
      break
    case GET_AVAILABLE_REQUESTS_COUNT_SUCCESS:
      return {
        ...state,
        availableRequestsCount: action.payload,
      }
      break
    case GET_AVERAGE_WAITING_TIMES_SUCCESS:
      return {
        ...state,
        averageWaitingTimes: action.payload,
      }
      break
    case GET_CALL_HISTORY_SUCCESS:
      return {
        ...state,
        callHistories: action.payload,
      }
      break
    case GET_CALL_EXTRA_SUCCESS:
      return {
        ...state,
        callExtraInfo: action.payload,
      }
      break
    case GET_PROVIDER_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      }
      break
    case GET_PROVIDER_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
      }
      break
    case GET_NDA_SUCCESS:
      return {
        ...state,
        nda: action.payload,
      }
    case GET_ACTIVE_REQUEST_COUNT_SUCCESSS:
      return {
        ...state,
        activeRequestsCount: action.payload,
      }
      break
    case GET_REQUESTS_WITH_COUPONS_SUCCESS:
      return {
        ...state,
        requestsWithCoupons: action.payload,
      }
    default:
      return state
  }
}

export const getDoctors = (
  searchText = null,
  providerTypeId = null
) => async dispatch => {
  try {
    const url = '/api/platform/admin/user/provider'
    const response = await axios.get(url, {
      params: {
        ...(searchText && {
          searchText,
        }),
        ...(providerTypeId && {
          providerTypeId,
        }),
      },
    })
    const { data } = response
    dispatch({
      type: GET_DOCTORS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getPatients = searchText => async dispatch => {
  try {
    const url = '/api/platform/admin/user/requester'
    const response = await axios.get(url, {
      params: {
        searchText: searchText,
      },
    })
    const { data } = response
    dispatch({
      type: GET_PATIENTS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const createUser = payload => async dispatch => {
  try {
    await axios.post('/api/platform/admin/user', {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const updateUser = payload => async dispatch => {
  try {
    const { id } = payload
    await axios.put(`/api/platform/admin/user/${id}`, {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const deleteUser = ({ id }) => async dispatch => {
  try {
    await axios.delete(`/api/platform/admin/user/${id}`)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const updateProviderTypeData = payload => async dispatch => {
  try {
    const { id } = payload
    await axios.put(`/api/platform/admin/user/providerData/${id}`, {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getProviderProfileById = userId => async dispatch => {
  try {
    const url = `/api/platform/admin/user/providerData/${userId}`
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_PROVIDER_PROFILE_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getProviderProfiles = providerTypeId => async dispatch => {
  try {
    const url = `/api/platform/admin/user/providerData/providerType/${providerTypeId}`
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_PROVIDER_PROFILES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getSymptoms = (
  searchText = null,
  providerTypeId = null
) => async dispatch => {
  try {
    const url = '/api/platform/admin/requestType'
    const response = await axios.get(url, {
      params: {
        ...(searchText && {
          searchText,
        }),
        ...(providerTypeId && {
          providerTypeId,
        }),
      },
    })
    const { data } = response
    dispatch({
      type: GET_SYMPTOMS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const createSymptom = payload => async dispatch => {
  try {
    await axios.post('/api/platform/admin/requestType', {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const updateSymptom = payload => async dispatch => {
  try {
    const { id } = payload
    await axios.put(`/api/platform/admin/requestType/${id}`, {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const deleteSymptom = ({ id }) => async dispatch => {
  try {
    await axios.delete(`/api/platform/admin/requestType/${id}`)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const updateSymptomSortOrder = payload => async dispatch => {
  try {
    const { id } = payload
    await axios.put(`/api/platform/admin/requestType/sortOrder/${id}`, payload)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getTips = searchText => async dispatch => {
  try {
    let url = '/api/platform/admin/tip'
    if (searchText) {
      url += `?searchText=${searchText}`
    }
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_TIPS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const createTip = payload => async dispatch => {
  try {
    await axios.post('/api/platform/admin/tip', {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const updateTip = payload => async dispatch => {
  try {
    const { id } = payload
    await axios.put(`/api/platform/admin/tip/${id}`, {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const deleteTip = ({ id }) => async dispatch => {
  try {
    await axios.delete(`/api/platform/admin/tip/${id}`)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getCountries = searchText => async dispatch => {
  try {
    let url = '/api/platform/admin/country'
    if (searchText) {
      url += `?searchText=${searchText}`
    }
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_COUNTRIES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const createCountry = payload => async dispatch => {
  try {
    await axios.post('/api/platform/admin/country', {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const updateCountry = payload => async dispatch => {
  try {
    const { id } = payload
    await axios.put(`/api/platform/admin/country/${id}`, {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const deleteCountry = ({ id }) => async dispatch => {
  try {
    await axios.delete(`/api/platform/admin/country/${id}`)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getFeedbacks = searchText => async dispatch => {
  try {
    let url = '/api/platform/admin/feedback'
    if (searchText) {
      url += `?searchText=${searchText}`
    }
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_FEEDBACKS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getAddresses = payload => async dispatch => {
  try {
    const { searchText, userId } = payload
    let url = `/api/platform/admin/address/${userId}`
    if (searchText) {
      url += `?searchText=${searchText}`
    }
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_ADDRESSES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const createAddress = payload => async dispatch => {
  try {
    const { userId } = payload
    await axios.post(`/api/platform/admin/address/${userId}`, {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const updateAddress = payload => async dispatch => {
  try {
    const { id } = payload
    await axios.put(`/api/platform/admin/address/${id}`, {
      ...payload,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const deleteAddress = ({ id }) => async dispatch => {
  try {
    await axios.delete(`/api/platform/admin/address/${id}`)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getProviderTypes = () => async dispatch => {
  try {
    const url = '/api/platform/admin/providerType'
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_PROVIDER_TYPES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getAvailableProvidersCount = () => async dispatch => {
  try {
    const url = '/api/platform/admin/status/providers'
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_AVAILABLE_PROVIDERS_COUNT_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getAvailableRequestsCount = () => async dispatch => {
  try {
    const url = '/api/platform/admin/status/requests'
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_AVAILABLE_REQUESTS_COUNT_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getActiveRequestCount = () => async dispatch => {
  try {
    const url = '/api/platform/admin/status/activeRequests'
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_ACTIVE_REQUEST_COUNT_SUCCESSS,
      payload: data.length,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getAverageWaitingTimes = providerTypeId => async dispatch => {
  try {
    const url = `/api/platform/admin/status/averageWaitingTimes/${providerTypeId}`
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_AVERAGE_WAITING_TIMES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getCallsHistory = searchText => async dispatch => {
  try {
    const url = '/api/platform/admin/calls'
    const response = await axios.get(url, {
      params: {
        searchText: searchText,
      },
    })
    const { data } = response
    dispatch({
      type: GET_CALL_HISTORY_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getCallExtraInfo = callId => async dispatch => {
  try {
    const url = `/api/platform/admin/callsExtra/${callId}`
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_CALL_EXTRA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getNda = () => async dispatch => {
  try {
    const url = '/api/platform/admin/nda'
    const response = await axios.get(url)
    const { data } = response
    dispatch({
      type: GET_NDA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getRequestsWithCoupons = () => async dispatch => {
  try {
    const url = '/api/platform/admin/coupon-requests'
    const response = await axios.get(url)
    const { data } = response
    
    dispatch({
      type: GET_REQUESTS_WITH_COUPONS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}
