import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'

const styles = theme => ({
  container: {
    textAlign: 'left',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '20px',
    marginBottom: '10px',
  },
  text: {
    color: 'limegreen',
    fontSize: '16px',
    marginBottom: '5px',
    marginLeft: '10px',
  }
})

const CouponUsageBlock = ({ couponUsageList, classes }) => (
  <React.Fragment className={classes.container}>
    <Grid item className={classes.container}>
      <Typography className={classes.title}>{`Kuponhasználat:`}</Typography>
      {couponUsageList.map(coupon => (
        <Typography key={coupon[0]} className={classes.text} gutterBottom>
          {`${coupon[0]}: ${coupon[1]}`}
        </Typography>
      ))}
    </Grid>
  </React.Fragment>
)

export default withStyles(styles)(CouponUsageBlock)
