import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuIcon from '@material-ui/icons/Menu'
import TypeSelector from '../common/TypeSelector'

const styles = theme => ({
  button: {
    margin: 0,
    marginBottom: '20px',
    width: '100%',
  },
  editButton: {
    width: '100%',
  },
  btnContainer: {
    textAlign: 'right',
  },
  selectContainer: {
    alignItems: 'center',
  },
  card: {
    boxShadow: 'none',
  },
  dragContainer: {
    cursor: 'row-resize',
  },
})

const SymptomCardList = ({
  symptoms,
  providerTypes,
  selectedProviderType,
  classes,
  handleAdd,
  handleEdit,
  handleDelete,
  handleSearch,
  handleTypeSelected,
  handleSortOrder,
  handleOnDragStart,
  handleOnDragOver,
  handleOnDragEnd,
}) => (
  <React.Fragment>
    <div className={classes.btnContainer}>
      <Button
        onClick={handleAdd}
        size="small"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        <AddIcon />
      </Button>
    </div>
    <div className={classes.selectContainer}>
      <TypeSelector
        types={providerTypes}
        selectedType={selectedProviderType}
        displayParam="name"
        handleTypeSelected={handleTypeSelected}
      />
    </div>
    {symptoms.map((symptom, idx) => {
      return (
        <Card className={classes.card} key={symptom.id}>
          <CardContent>
            <IconButton
              className={`${classes.button} ${classes.dragContainer}`}
              draggable
              onDragStart={e => handleOnDragStart(e, idx)}
              onDragOver={e => handleOnDragOver(e, idx)}
              onDragEnd={handleOnDragEnd}
              onDrop={() => {
                handleSortOrder(symptoms)
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="p">
              {`Megnevezés: ${symptom.title}`}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                handleEdit(symptom)
              }}
              size="small"
              variant="contained"
              color="primary"
              className={classes.editButton}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                handleDelete({ id: symptom.id })
              }}
              size="small"
              variant="contained"
              color="primary"
              className={classes.editButton}
            >
              <DeleteIcon />
            </Button>
          </CardActions>
        </Card>
      )
    })}
  </React.Fragment>
)

export default withStyles(styles)(SymptomCardList)
