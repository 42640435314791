import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'

import DoctorIcon from '@material-ui/icons/AssignmentInd'
import PatientIcon from '@material-ui/icons/Face'
import SymptomIcon from '@material-ui/icons/ReportProblem'
import TipIcon from '@material-ui/icons/FormatQuote'
import FeedbackIcon from '@material-ui/icons/Feedback'
import CountryIcon from '@material-ui/icons/Map'
import CallIcon from '@material-ui/icons/Call'
import BookIcon from '@material-ui/icons/Book'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
  },
  activeLink: {
    display: 'inline-block',
    width: '100%',
    backgroundColor: '#dcdbdb',
  },
})

const SideMenu = ({ classes }) => (
  <Drawer
    variant="permanent"
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <List component="nav">
      <NavLink
        className={classes.link}
        to="/dashboard/status"
        isActive={() => window.location.pathname === '/dashboard/status'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <CountryIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Állapot" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/doctors"
        isActive={() =>
          window.location.pathname === '/dashboard/doctors' ||
          window.location.pathname === '/dashboard/doctors/addresses'
        }
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <DoctorIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Orvosok/Pszichológusok" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/patients"
        isActive={() =>
          window.location.pathname === '/dashboard/patients' ||
          window.location.pathname === '/dashboard/patients/addresses'
        }
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <PatientIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Páciensek" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/nda"
        isActive={() => window.location.pathname === '/dashboard/nda'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Best Practice Lista" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/symptoms"
        isActive={() => window.location.pathname === '/dashboard/symptoms'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <SymptomIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Tünetek" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/tips"
        isActive={() => window.location.pathname === '/dashboard/tips'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <TipIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Tippek" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/calls"
        isActive={() => window.location.pathname === '/dashboard/calls'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <CallIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Hívásnapló" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/countries"
        isActive={() => window.location.pathname === '/dashboard/countries'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <CountryIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Számlacím országok" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/feedbacks"
        isActive={() => window.location.pathname === '/dashboard/feedbacks'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <FeedbackIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Visszajelzések" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/coupon-stats"
        isActive={() => window.location.pathname === '/dashboard/coupon-stats'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <CardGiftcardIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Kuponok" />
          </Hidden>
        </ListItem>
      </NavLink>
      <NavLink
        className={classes.link}
        to="/dashboard/vouchers"
        isActive={() => window.location.pathname === '/dashboard/vouchers'}
        activeClassName={classes.activeLink}
      >
        <ListItem button>
          <Hidden only={['sm']}>
            <ListItemIcon>
              <CardGiftcardIcon />
            </ListItemIcon>
          </Hidden>
          <Hidden only={['xs']}>
            <ListItemText primary="Kuponok (új)" />
          </Hidden>
        </ListItem>
      </NavLink>
    </List>
  </Drawer>
)

SideMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SideMenu)
