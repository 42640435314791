import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'

import Logo from '../../assets/images/logo.png'
import ExitIcon from '@material-ui/icons/ExitToApp'
import LanguageSelector from '../languageSelector/LanguageSelector'

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background:
      'linear-gradient( 45deg, rgb(63,167,240) 0%, rgb(240,30,90) 100%)',
  },
  image: {
    margin: 'auto',
    marginLeft: '45%',
  },
  toolbar: theme.mixins.toolbar,
  button: {
    color: 'white',
  },
})

const Header = ({ classes, onLogout }) => (
  <AppBar position="static" className={classes.appBar}>
    <Toolbar>
      <img alt="logo" className={classes.image} width={100} src={Logo} />
      <Suspense fallback='loading'>
        <LanguageSelector/>
      </Suspense>
      <IconButton
        onClick={onLogout}
        className={classes.button}
        aria-label="Exit"
      >
        <ExitIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
)

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Header)
