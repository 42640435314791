import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import Search from '../common/Search'
import TypeSelector from '../common/TypeSelector'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  btnContainer: {
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  searchContainer: {
    textAlign: 'left',
  },
  onlineIcon: {
    color: 'limegreen',
  },
  offlineIcon: {
    color: 'grey',
  },
})

const DoctorTable = ({
  doctors,
  classes,
  handleAddressList,
  handleTypeSelected,
  providerTypes,
  selectedProviderType,
  handleAdd,
  handleEdit,
  handleExport,
  handleDelete,
  handleSearch,
  handleValidationSwitch,
  searchText,
  formatDate,
}) => (
  <React.Fragment>
    <Grid container justify="center">
      <Grid className={classes.searchContainer} item md={4}>
        <Search handleSearch={handleSearch} />
      </Grid>
      <Grid className={classes.searchContainer} item md={4}>
        <TypeSelector
          types={providerTypes}
          selectedType={selectedProviderType}
          displayParam="name"
          handleTypeSelected={handleTypeSelected}
        />
      </Grid>
      <Grid className={classes.btnContainer} item md={4}>
        <Button
          onClick={handleExport}
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Export
        </Button>
        <Button
          onClick={handleAdd}
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Titulus</TableCell>
          <TableCell>Vezetéknév</TableCell>
          <TableCell>Keresztnév</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Telefonszám</TableCell>
          <TableCell>Születési dátum</TableCell>
          <TableCell>Számlázási címek</TableCell>
          <TableCell>Bejelentkezve</TableCell>
          <TableCell>Regisztráció ideje</TableCell>
          <TableCell>Jóváhagyott</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {doctors.map(doctor => {
          return (
            <TableRow key={doctor.id}>
              <TableCell>{doctor.id}</TableCell>
              <TableCell>{doctor.namePrefix}</TableCell>
              <TableCell>{doctor.lastName}</TableCell>
              <TableCell>{doctor.firstName}</TableCell>
              <TableCell>{doctor.email}</TableCell>
              <TableCell>{doctor.phoneNumber}</TableCell>
              <TableCell>{doctor.birthday}</TableCell>
              <TableCell>
                <Link
                  className={classes.link}
                  to={{
                    pathname: '/dashboard/doctors/addresses',
                    state: { userId: doctor.id },
                  }}
                >
                  <IconButton className={classes.button} aria-label="List">
                    <ListIcon />
                  </IconButton>
                </Link>
              </TableCell>
              <TableCell>
                <FiberManualRecordIcon
                  className={
                    doctor.availableSince
                      ? classes.onlineIcon
                      : classes.offlineIcon
                  }
                />
              </TableCell>
              <TableCell>{formatDate(doctor.createdAt)}</TableCell>
              <TableCell>
                <Switch
                  checked={doctor.validated}
                  onChange={() => {
                    handleValidationSwitch(doctor)
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    handleEdit(doctor)
                  }}
                  className={classes.button}
                  aria-label="Edit"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDelete({ id: doctor.id })
                  }}
                  className={classes.button}
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

export default withStyles(styles)(DoctorTable)
