import React from 'react'
import {
  TextField,
  Card,
} from '@material-ui/core'

import './ProfileTextField.css'

const ProfileTextField = ({
  label,
  value,
  handleChange,
  handleChangeTarget,
}) => (
  <Card className="row">
    <div className="description">
      <p>
        <b>{label}</b>
      </p>
      <TextField
        className="profile_textfield_input"
        margin="normal"
        required
        value={value || ''}
        onChange={e => handleChange(handleChangeTarget, e.target.value)}
        fullWidth
      />
    </div>
  </Card>
)

export default ProfileTextField
