import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const styles = theme => ({})

const NdaTable = ({ nda, classes, formatDate }) => (
  <React.Fragment>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Név</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Cím</TableCell>
          <TableCell>Létrehozás ideje</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {nda.map(e => {
          return (
            <TableRow key={e.id}>
              <TableCell>{e.id}</TableCell>
              <TableCell>{e.name}</TableCell>
              <TableCell>{e.email}</TableCell>
              <TableCell>{e.address}</TableCell>
              <TableCell>{formatDate(e.createdAt)}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

export default withStyles(styles)(NdaTable)
