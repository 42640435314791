/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,  
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Divider,
  Switch,
  Toolbar,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { VouchersTable } from './VouchersTable'
import { VoucherForm } from './VoucherForm'
import { useAxios } from '../../hooks'

const strings = {
  addCoupon: 'Új kupon',
  showExpired: 'Mutasd a lejárt kuponokat is',
}

const VouchersView = ({}) => {
  const [view, setView] = useState('')
  const [selectedVoucher, setSelectedVoucher] = useState()
  const [includeExpired, setIncludeExpired] = useState(false)

  const { response: vouchers, error, loading, executeRequest } = useAxios({
    method: 'GET',
    url: '/vouchers',
  })

  const handleAdd = () => {
    setView('add')
  }

  const handleSave = (newVoucher) => {
    setView('')
    setSelectedVoucher(null)
    console.log('voucher that was created =', newVoucher)
  }

  const handleClose = () => {
    setView('')
    setSelectedVoucher(null)
    console.log('canceled')
  }

  const handleEdit = voucher => {
    setSelectedVoucher(voucher)
    setView('edit')
    console.log('edit voucher', voucher)
  }

  const handleDelete = voucher => {
    executeRequest()
    setSelectedVoucher(voucher)
    console.log('delete voucher', voucher)
  }

  const refreshVouchers = (event) => {
    setIncludeExpired(!includeExpired)
  }

  useEffect(() => {
    executeRequest({
      method: 'get',
      url: `/vouchers?expired=${includeExpired}`
    })    
  }, [includeExpired])

  return (
    <>
      <Toolbar>
        <Button
          variant="contained"
          onClick={handleAdd}
          disabled={view !== '' || loading}
        >
          {strings.addCoupon} <AddIcon style={{ marginLeft: '1rem' }} />
        </Button>
        <FormGroup style={{ marginLeft: '1rem' }}>
          <FormControlLabel
            control={<Switch checked={includeExpired} disabled={view !== '' || loading} onChange={refreshVouchers} />}
            label={strings.showExpired}
          />
        </FormGroup>
      </Toolbar>
      <Divider />

      {loading && <CircularProgress />}
      {error && <Typography variant="h2">{error}</Typography>}

      {!view && (
        <VouchersTable
          vouchers={vouchers && vouchers.records}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
      {view === 'add' && (
        <VoucherForm onSave={handleSave} onCancel={handleClose} voucher={selectedVoucher} />
      )}
      {view === 'edit' && (
        <VoucherForm onSave={handleSave} onCancel={handleClose} voucher={selectedVoucher} />
      )}
    </>
  )
}

VouchersView.displayName = 'VouchersView'

VouchersView.propTypes = {}

VouchersView.defaultProps = {}

export default VouchersView
export { VouchersView }
