import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import TypeSelector from '../common/TypeSelector'

const styles = theme => ({
  selectContainer: {
    alignItems: 'center',
  },
  card: {
    boxShadow: 'none',
  },
})

const WaitingTimeCardList = ({
  waitingTimes,
  classes,
  handleTypeSelected,
  providerTypes,
  selectedProviderType,
}) => (
  <React.Fragment>
    <div className={classes.selectContainer}>
      <TypeSelector
        types={providerTypes}
        selectedType={selectedProviderType}
        displayParam="name"
        handleTypeSelected={handleTypeSelected}
      />
    </div>
    {waitingTimes.map((waitingTime, idx) => {
      return (
        <Card className={classes.card} key={idx}>
          <CardContent>
            <Typography component="p">
              {`${waitingTime.timeIntervalStart} - ${waitingTime.timeIntervalEnd}`}
            </Typography>
            <Typography component="p">
              {`Hívások száma: ${waitingTime.elementCount}`}
            </Typography>
            <Typography component="p">
              {`Átlagos várakozási idő: ${waitingTime.averageWaitingTime}`}
            </Typography>
          </CardContent>
        </Card>
      )
    })}
  </React.Fragment>
)

export default withStyles(styles)(WaitingTimeCardList)
