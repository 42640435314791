import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'

import ListIcon from '@material-ui/icons/List'

import Search from '../common/Search'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  btnContainer: {
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  searchContainer: {
    textAlign: 'left',
  },
})

const CallHistoryTable = ({
  calls,
  classes,
  handleShowCallExtraInfo,
  formatDate,
  formatCallDuration,
  handleSearch,
  userType,
}) => (
  <React.Fragment>
    <Grid container justify="center">
      <Grid className={classes.searchContainer} item md={12}>
        <Search handleSearch={handleSearch} />
      </Grid>
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Számlaszám</TableCell>
          <TableCell>Szakterület</TableCell>
          <TableCell>Konzultáció nyelve</TableCell>
          <TableCell>Orvos neve</TableCell>
          <TableCell>Orvos email</TableCell>
          <TableCell>Páciens neve</TableCell>
          <TableCell>Páciens email</TableCell>
          <TableCell>Hívás időtartama</TableCell>
          <TableCell>Hívás kezdete</TableCell>
          <TableCell>Hívás vége</TableCell>
          {userType == 'supervisorDoctor' && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {calls.map(call => {
          return (
            <TableRow key={call.id}>
              <TableCell>{call.id}</TableCell>
              <TableCell>{call.transactionId}</TableCell>
              <TableCell>
                {call.providerTypeId === 1 ? 'Orvos' : 'Pszichológus'}
              </TableCell>
              <TableCell>
                <LanguageDisplay language={call.language} />
              </TableCell>
              <TableCell>{call.providerName}</TableCell>
              <TableCell>{call.providerEmail}</TableCell>
              <TableCell>{call.userName}</TableCell>
              <TableCell>{call.userEmail}</TableCell>
              <TableCell>{formatCallDuration(call.callSeconds)}</TableCell>
              <TableCell>{formatDate(call.callStartedAt)}</TableCell>
              <TableCell>{formatDate(call.callEndedAt)}</TableCell>
              {userType == 'supervisorDoctor' && (
                <TableCell>
                  <IconButton
                    onClick={() => {
                      handleShowCallExtraInfo(call)
                    }}
                    className={classes.button}
                    aria-label="Show info"
                  >
                    <ListIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

const LanguageDisplay = ({ language }) => {
  if (language === 'en') {
    return 'Angol'
  }

  return 'Magyar'
}

LanguageDisplay.displayName = ''

export default withStyles(styles)(CallHistoryTable)
