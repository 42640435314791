import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as _ from 'lodash'

import { getRequestsWithCoupons } from '../../modules/admin'

import CouponTable from '../../components/coupon/CouponTable'
import CouponUsageBlock from '../../components/coupon/CouponUsageBlock'
import formatDate from '../../utils/dateFormatter'

class CouponContainer extends React.Component {
  componentDidMount () {
    this.props.getRequestsWithCoupons()
  }

  getCouponUsageList (requestsWithCoupons) {
    const codeUsageList = _.chain(requestsWithCoupons)
      .countBy('couponCode')
      .toPairs()
      .value()

    return codeUsageList
  }

  render () {
    console.log('coupon container render')
    const { requestsWithCoupons } = this.props
    const couponUsageList = this.getCouponUsageList(requestsWithCoupons)
    if (!couponUsageList) return null

    return (
      <React.Fragment>
        <CouponUsageBlock couponUsageList={couponUsageList} />
        <CouponTable
          requestsWithCoupons={requestsWithCoupons}
          formatDate={formatDate}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  requestsWithCoupons: admin.requestsWithCoupons,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRequestsWithCoupons,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CouponContainer)
