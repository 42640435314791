import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  container: {
    marginTop: 16,
    marginBottom: 8,
  },
})

const TypeSelector = ({
  classes,
  className,
  types,
  selectedType,
  displayParam,
  handleTypeSelected,
  title,
  disabledFirst,
}) => (
  <Fragment>
    <FormControl className={`${classes.container} ${className ? className : ''}`}>
      <InputLabel>{title || 'Válasszon'}</InputLabel>
      <Select
        id="types"
        value={selectedType}
        placeholder={title}
        style={{ textAlign: 'left' }}
        onChange={event => handleTypeSelected(event.target.value)}
      >
        {types.map((type, index) => (
          <MenuItem
            disabled={disabledFirst && index === 0}
            key={type.id}
            value={type}
          >
            {type[displayParam]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Fragment>
)

TypeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  selectedType: (props, propName, componentName) => {
    if (!props['types'].includes(props[propName])) {
      return new Error('Selected type not found in given types')
    }
  },
  handleTypeSelected: PropTypes.func,
  displayParam: (props, propName, componentName) => {
    if (!Object.keys(props['types'][0]).includes(propName)) {
      return new Error('Display param must be a valid parameter of given types')
    }
  },
}

TypeSelector.defaultProps = {
  title: 'Válasszon',
}

export default withStyles(styles)(TypeSelector)
