import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'

import {
  getAddresses,
  createAddress,
  updateAddress,
  deleteAddress,
  getCountries,
} from '../../modules/admin'

import AddressTable from '../../components/address/AddressTable'
import AddressForm from '../../components/address/AddressForm'
import AddressCardList from '../../components/address/AddressCardList'

import confirmDelete from '../../utils/confirmDelete'

class AddressContainer extends React.Component {
  state = {
    showAddressForm: false,
    countries: null,
    selectedAddress: {},
  }

  async componentDidMount() {
    await this.props.getCountries()
    this.setState({
      countries: this.props.countries,
    })

    const { userId } = this.props.location.state
    this.props.getAddresses({ userId })
  }

  showForm = () => {
    this.setState({
      showAddressForm: true,
    })
  }

  handleEdit = address => {
    this.setState({
      selectedAddress: address,
      showAddressForm: true,
    })
  }

  submitForm = async payload => {
    const { userId } = this.props.location.state
    if (!payload.id) {
      await this.props.createAddress({ ...payload, userId })
    } else {
      await this.props.updateAddress({ ...payload })
    }
    this.props.getAddresses({ userId })
    this.setState({
      showAddressForm: false,
    })
  }

  handleDelete = async ({ id }) => {
    const canDelete = await confirmDelete(
      this.props.addresses,
      id,
      'stringRepresentation'
    )
    if (canDelete) {
      const { userId } = this.props.location.state
      await this.props.deleteAddress({ id })
      await this.props.getAddresses({ userId })
    }
  }

  handleSearch = async searchText => {
    const { userId } = this.props.location.state
    await this.props.getAddresses({ searchText, userId })
  }

  handleBack = () => {
    this.setState({
      showAddressForm: false,
      selectedAddress: {},
    })
  }

  render() {
    const { addresses } = this.props
    const { showAddressForm, selectedAddress, countries } = this.state
    return (
      <React.Fragment>
        {!showAddressForm && (
          <React.Fragment>
            <Hidden only={['sm', 'xs']}>
              <AddressTable
                handleAdd={this.showForm}
                handleSearch={this.handleSearch}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                addresses={addresses}
              />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <AddressCardList
                handleAdd={this.showForm}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                addresses={addresses}
              />
            </Hidden>
          </React.Fragment>
        )}
        {showAddressForm && (
          <AddressForm
            countries={countries}
            address={selectedAddress}
            onBackClick={this.handleBack}
            onSubmit={this.submitForm}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  addresses: admin.addresses,
  countries: admin.countries,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAddresses,
      createAddress,
      updateAddress,
      deleteAddress,
      getCountries,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AddressContainer)
