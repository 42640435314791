import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ListIcon from '@material-ui/icons/List'

import Search from '../common/Search'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  btnContainer: {
    textAlign: 'right',
  },
  searchContainer: {
    textAlign: 'left',
  },
})

const PatientTable = ({
  patients,
  classes,
  handleAddressList,
  handleAdd,
  handleEdit,
  handleDelete,
  handleSearch,
  formatDate,
}) => (
  <React.Fragment>
    <Grid container justify="center">
      <Grid className={classes.searchContainer} item md={8}>
        <Search handleSearch={handleSearch} />
      </Grid>
      <Grid className={classes.btnContainer} item md={4}>
        <Button
          onClick={handleAdd}
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Név</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Születési dátum</TableCell>
          <TableCell>Számlázási címek</TableCell>
          <TableCell>Regisztráció ideje</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {patients.map(patient => {
          return (
            <TableRow key={patient.id}>
              <TableCell>{patient.name}</TableCell>
              <TableCell>{patient.email}</TableCell>
              <TableCell>{patient.birthday}</TableCell>
              <TableCell>
                <Link
                  className={classes.link}
                  to={{
                    pathname: '/dashboard/patients/addresses',
                    state: { userId: patient.id },
                  }}
                >
                  <IconButton className={classes.button} aria-label="List">
                    <ListIcon />
                  </IconButton>
                </Link>
              </TableCell>
              <TableCell>{formatDate(patient.createdAt)}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    handleEdit(patient)
                  }}
                  className={classes.button}
                  aria-label="Edit"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDelete({ id: patient.id })
                  }}
                  className={classes.button}
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

export default withStyles(styles)(PatientTable)
