import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'
import decodeUserType from '../../utils/jwt-decode'

import { getCallsHistory, getCallExtraInfo } from '../../modules/admin'

import CallHistoryTable from '../../components/callHistory/CallHistoryTable'
import CallHistoryExtraInfoCardList from '../../components/callHistory/CallHistoryExtraInfoCardList'
import CallHistoryCardList from '../../components/callHistory/CallHistoryCardList'

import formatDate from '../../utils/dateFormatter'

class CallHistoryContainer extends React.Component {
  state = {
    showCallExtraInfo: false,
    selectedCall: {},
    searchText: '',
  }

  async componentDidMount() {
    await this.props.getCallsHistory()
  }

  handleShowCallExtraInfo = async call => {
    await this.props.getCallExtraInfo(call.id)
    this.setState({
      showCallExtraInfo: true,
      selectedCall: call,
    })
  }

  handleBack = () => {
    this.setState({
      showCallExtraInfo: false,
      selectedCall: {},
    })
  }

  handleSearch = async searchText => {
    await this.props.getCallsHistory(searchText)
  }

  formatCallDuration = callDuration => {
    const minutes = Math.floor(callDuration / 60)
    const seconds = Math.floor(callDuration % 60)
    const minuteString = minutes != 0 ? `${minutes}p` : ''

    return `${minuteString} ${seconds}mp`
  }

  render() {
    const { callHistories, callExtraInfo, userType } = this.props
    const { showCallExtraInfo, searchText } = this.state
    return (
      <React.Fragment>
        {!showCallExtraInfo && (
          <React.Fragment>
            <Hidden only={['sm', 'xs']}>
              <CallHistoryTable
                calls={callHistories}
                handleShowCallExtraInfo={this.handleShowCallExtraInfo}
                formatDate={formatDate}
                formatCallDuration={this.formatCallDuration}
                searchText={searchText}
                handleSearch={this.handleSearch}
                userType={userType}
              />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <CallHistoryCardList
                calls={callHistories}
                handleShowCallExtraInfo={this.handleShowCallExtraInfo}
                formatDate={formatDate}
                formatCallDuration={this.formatCallDuration}
                userType={userType}
              />
            </Hidden>
          </React.Fragment>
        )}
        {showCallExtraInfo && (
          <CallHistoryExtraInfoCardList
            callExtraInfo={callExtraInfo}
            onBackClick={this.handleBack}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin, user }) => ({
  callHistories: admin.callHistories,
  callExtraInfo: admin.callExtraInfo,
  userType: decodeUserType(user.token),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCallsHistory,
      getCallExtraInfo,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallHistoryContainer)
