import React from 'react'
import ProfileTextField from '../../components/profile/ProfileTextField'
import Upload from '../../components/profile/Upload'
import { LanguageChips } from '../../components/profile/LanguageChips'

import '../../containers/profile/ProfileContainer.css'
import { useTranslation } from 'react-i18next'

const DoctorPage = ({
  medicalDegreeUrl,
  specialistCertificateUrl,
  aeekCardFrontUrl,
  aeekCardBackUrl,
  insuranceUrl,
  chamberMembershipNumber,
  handleChange,
  uploading,
  upload,
  download,
  languages,
}) => {
  const { t } = useTranslation('doctorPage')
  return (
    <React.Fragment>
      <LanguageChips
        label={t('languages')}
        languages={languages}
        onChange={handleChange}
      />

      <Upload
        name={t('generalDiploma')}
        downloadLink={medicalDegreeUrl}
        fileName="medicalDegree"
        onSelect={upload}
        download={download}
        isUploading={uploading.includes('medicalDegree')}
      />
      <Upload
        name={t('specialistDiploma')}
        downloadLink={specialistCertificateUrl}
        fileName="specialistCertificate"
        onSelect={upload}
        download={download}
        isUploading={uploading.includes('specialistCertificate')}
      />
      <Upload
        name={t('aeekCardFront')}
        downloadLink={aeekCardFrontUrl}
        fileName="aeekCardFront"
        onSelect={upload}
        download={download}
        isUploading={uploading.includes('aeekCardFront')}
      />
      <Upload
        name={t('aeekCardBack')}
        downloadLink={aeekCardBackUrl}
        fileName="aeekCardBack"
        onSelect={upload}
        download={download}
        isUploading={uploading.includes('aeekCardBack')}
      />
      <Upload
        name={t('insurance')}
        downloadLink={insuranceUrl}
        fileName="insurance"
        onSelect={upload}
        download={download}
        isUploading={uploading.includes('insurance')}
      />

      <ProfileTextField
        label={t('registrationNumber')}
        value={chamberMembershipNumber}
        handleChange={handleChange}
        handleChangeTarget={'chamberMembershipNumber'}
      />
    </React.Fragment>
  )
}
export default DoctorPage
