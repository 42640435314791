import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'

import { unregister as unregisterServiceWorker } from './registerServiceWorker'

import store, { history, persistor } from './store'
import configureAxios from './configureAxios'


const theme = createMuiTheme({
  palette: {
    primary: { main: 'rgb(229, 39, 100)' },
  },
})

if(window.location.href.includes('best-practice')) {
  if(window.localStorage['persist:doki']) {
    const storageContent = JSON.parse(window.localStorage['persist:doki'])
    const user = JSON.parse(storageContent.user)
    if (user.token) {
      window.localStorage.clear()
      window.location.reload()
    }
  }
}

configureAxios(store)
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
unregisterServiceWorker()
