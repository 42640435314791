import React from 'react'
import Upload from '../../components/profile/Upload'
import { LanguageChips } from '../../components/profile/LanguageChips'

import '../../containers/profile/ProfileContainer.css'
import { useTranslation } from 'react-i18next'

const PsychoPage = ({
  medicalDegreeUrl,
  specialistCertificateUrl,
  homeAddressCardUrl,
  handleChange,
  uploading,
  upload,
  download,
  languages,
}) => {
  const { t } = useTranslation('psychoPage')
  return (
    <React.Fragment>
      <LanguageChips
        label={t('languages')}
        languages={languages}
        onChange={handleChange}
      />

      <Upload
        name={t('psychoDiploma')}
        downloadLink={medicalDegreeUrl}
        fileName="medicalDegree"
        onSelect={upload}
        download={download}
        isUploading={uploading.includes('medicalDegree')}
      />
      <Upload
        name={t('specialistDiploma')}
        downloadLink={specialistCertificateUrl}
        fileName="specialistCertificate"
        onSelect={upload}
        download={download}
        isUploading={uploading.includes('specialistCertificate')}
      />
      <Upload
        name={t('addressCard')}
        downloadLink={homeAddressCardUrl}
        fileName="homeAddressCard"
        onSelect={upload}
        download={download}
        isUploading={uploading.includes('homeAddressCard')}
      />
    </React.Fragment>
  )
}

export default PsychoPage
