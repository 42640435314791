import React from 'react'
import EnSvg from '../../assets/images/en.svg'
import HuSvg from '../../assets/images/hu.svg'
import UaSvg from '../../assets/images/ua.svg'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'

const styles = theme => ({
  en: {
    width: '70%',
    marginRight: '0.4vw',
  },
  hu: {
    width: '70%',
    marginRight: '0.4vw',
  },
  ua: {
    width: '70%',
    marginRight: '0.4vw',
  },
  huMargin: {
    width: '70%',
    marginBottom: '0.2vw',
    marginRight: '0.4vw',
  },
})

const CountryFlag = ({ classes, countryCode, state }) => {
  switch (countryCode) {
    case 'en':
      return (
        <IconButton>
          <img src={EnSvg} alt="EN flag icon" className={classes.en} />
        </IconButton>
      )
      break
    case 'ua':
      return (
        <IconButton>
          <img src={UaSvg} alt="UA flag icon" className={classes.ua} />
        </IconButton>
      )
      break
    default:
      return (
        <IconButton>
          <img
            src={HuSvg}
            alt="hu-icon"
            className={state === 'listItem' ? classes.hu : classes.huMargin}
          />
        </IconButton>
      )
      break
  }
}

CountryFlag.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CountryFlag)
