import React, { useEffect, useState } from 'react'


import * as Ably from 'ably'
import * as _ from 'lodash'

// const ably = new Ably.Realtime('KDaOGg.iRYsbw:VNREb8YvbQ7qX2Jl')
const realtime = new Ably.Realtime({
  key: 'KDaOGg.5-nnlA:yEYwtAt009Uu0Ww3',
})
const channel = realtime.channels.get('providers')

interface Member {
  clientId: string;
  action: any;
  encoding?: any;
  data: Data;
}

interface Data {
  id: number;
  email: string;
  userTypeId: number;
  birthday?: any;
  avatarUrl?: any;
  createdAt: string;
  namePrefix?: any;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  validated: boolean;
  availableSince?: any;
  providerTypes: number[];
  addresses: any[];
}
export const ProviderPresence = () => {

  const [lastId, setLastId] = useState('lastId')
  const [providers, setProviders] = useState<any>({ '1': [], '2': [] })

  console.log({ lastId })
  useEffect(() => {
    channel.presence.subscribe((msg) => {
      console.log('woof', msg)
      setLastId(msg.id)
    })
    return () => channel.presence.unsubscribe()
  }, [])

  useEffect(() => {
    channel.presence.get((err, members: Member[]) => {
      console.log('There are ' + members.length + ' members on this channel');
      if (members.length === 0) {
        setProviders({ '1': [], '2': [] })
      }
      // console.log('The first member has client ID: ' + members[0].clientId);
      console.log(members)
      const formattedMembers = members.map((member) => member.data)
      const grouped = _.groupBy(formattedMembers, (member) => {
        return member.providerTypes[0]
      })
      if(!grouped['1']) grouped['1'] = []
      if(!grouped['2']) grouped['2'] = []
      setProviders(grouped)
      console.log({ grouped })

    });
  }, [lastId])

  console.log({providers})
  if (!providers['1'] || !providers['2']) return null
  const doctors = providers['1'].map((provider: Data) => {
    return (<li key={provider.id}>{provider.namePrefix} {provider.lastName} {provider.firstName} - {provider.email}</li>)
  })

  const psychologists = providers['2'].map((provider: Data) => {
    return (<li key={provider.id}>{provider.namePrefix} {provider.lastName} {provider.firstName} - {provider.email}</li>)
  })

  return (<div>

    <h2>Orvosok:</h2>
    <ul>
      {doctors}
    </ul>

    <h2>Pszichologusok:</h2>
    <ul>
      {psychologists}
    </ul>
  </div>)

  return null
}
