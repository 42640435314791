import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'

import {
  getSymptoms,
  createSymptom,
  updateSymptom,
  deleteSymptom,
  updateSymptomSortOrder,
  getProviderTypes,
} from '../../modules/admin'

import SymptomTable from '../../components/symptom/SymptomTable'
import SymptomForm from '../../components/symptom/SymptomForm'
import SymptomCardList from '../../components/symptom/SymptomCardList'

import confirmDelete from '../../utils/confirmDelete'

class SymptomContainer extends React.Component {
  state = {
    showSymptomForm: false,
    selectedSymptom: {},
    searchText: '',
    selectedProviderType: {},
    symptoms: [],
  }

  async componentDidMount() {
    await this.props.getProviderTypes()
    this.setState({
      selectedProviderType: this.props.providerTypes[0],
    })
    await this.props.getSymptoms(null, this.state.selectedProviderType.id)
    this.setState({ symptoms: this.props.symptoms })
  }

  showForm = () => {
    this.setState({
      showSymptomForm: true,
    })
  }

  handleEdit = symptom => {
    this.setState({
      selectedSymptom: symptom,
      showSymptomForm: true,
    })
  }

  submitForm = async payload => {
    if (!payload.id) {
      await this.props.createSymptom({ ...payload })
    } else {
      await this.props.updateSymptom({ ...payload })
    }
    const { searchText, selectedProviderType } = this.state
    await this.props.getSymptoms(searchText, selectedProviderType.id)
    this.setState({
      showSymptomForm: false,
    })
    // Need reload to refresh the table
    window.location.reload()
  }

  handleDelete = async ({ id }) => {
    const canDelete = await confirmDelete(this.props.symptoms, id, 'title')
    if (canDelete) {
      await this.props.deleteSymptom({ id })
      const { searchText, selectedProviderType } = this.state
      await this.props.getSymptoms(searchText, selectedProviderType.id)

      // Need reload to refresh the table
      window.location.reload()
    }
  }

  handleSortOrder = async payload => {
    await Promise.all(
      payload.map(async (payload, idx) => {
        payload['sortOrder'] = idx
        await this.props.updateSymptomSortOrder({ ...payload })
      })
    )
    await this.props.getSymptoms(null, this.state.selectedProviderType.id)
  }

  handleTypeSelected = async selectedProviderType => {
    this.setState({
      selectedProviderType,
    })
    const { searchText } = this.state
    await this.props.getSymptoms(searchText, selectedProviderType.id)
    this.setState({ symptoms: this.props.symptoms })
  }

  handleSearch = async searchText => {
    this.setState({
      searchText,
    })
    const { selectedProviderType } = this.state
    await this.props.getSymptoms(searchText, selectedProviderType.id)
    this.setState({ symptoms: this.props.symptoms })
  }

  handleBack = () => {
    this.setState({
      showSymptomForm: false,
      selectedSymptom: {},
    })
  }

  handleOnDragStart = (e, index) => {
    this.draggedItem = this.state.symptoms[index]
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.effectAllowed = 'move'
    //e.dataTransfer.setDragImage(e.target, 40, 40);
  }

  handleOnDragOver = (e, index) => {
    e.preventDefault()
    const draggedOverItem = this.state.symptoms[index]

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return
    }

    // filter out the currently dragged item
    const items = this.state.symptoms.filter(item => item !== this.draggedItem)

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem)

    this.setState({ symptoms: items })

    e.dataTransfer.dropEffect = 'move'
  }

  handleOnDragEnd = () => {
    this.draggedIdx = null
  }

  render() {
    const { providerTypes } = this.props
    const {
      symptoms,
      showSymptomForm,
      selectedSymptom,
      selectedProviderType,
    } = this.state
    return (
      <React.Fragment>
        {!showSymptomForm && (
          <React.Fragment>
            <Hidden only={['sm', 'xs']}>
              <SymptomTable
                handleAdd={this.showForm}
                handleSearch={this.handleSearch}
                handleTypeSelected={this.handleTypeSelected}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                handleSortOrder={this.handleSortOrder}
                handleOnDragStart={this.handleOnDragStart}
                handleOnDragOver={this.handleOnDragOver}
                handleOnDragEnd={this.handleOnDragEnd}
                symptoms={symptoms}
                providerTypes={providerTypes}
                selectedProviderType={selectedProviderType}
              />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <SymptomCardList
                handleAdd={this.showForm}
                handleSearch={this.handleSearch}
                handleTypeSelected={this.handleTypeSelected}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                handleSortOrder={this.handleSortOrder}
                handleOnDragStart={this.handleOnDragStart}
                handleOnDragOver={this.handleOnDragOver}
                handleOnDragEnd={this.handleOnDragEnd}
                symptoms={symptoms}
                providerTypes={providerTypes}
                selectedProviderType={selectedProviderType}
              />
            </Hidden>
          </React.Fragment>
        )}
        {showSymptomForm && (
          <SymptomForm
            symptom={selectedSymptom}
            selectedProviderType={selectedProviderType}
            onBackClick={this.handleBack}
            onSubmit={this.submitForm}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  symptoms: admin.symptoms,
  providerTypes: admin.providerTypes,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSymptoms,
      createSymptom,
      updateSymptom,
      deleteSymptom,
      updateSymptomSortOrder,
      getProviderTypes,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SymptomContainer)
