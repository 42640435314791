import jwtDecode from 'jwt-decode'

export default function(token) {
  if (!token) {
    return undefined
  }

  const {
    user: { userTypeId },
  } = jwtDecode(token)

  switch (userTypeId) {
    case 1:
      return 'doctor'
    case 3:
      return 'admin'
    case 4:
    case 5:
      return 'supervisorDoctor'
    default:
      return 'unknown'
  }
}
