/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core'
import { useAxios } from '../../hooks'

const limits = [
  {
    id: 'therapist',
    text: 'Pszichológus',
  },
  {
    id: 'md',
    text: 'Orvos',
  },
]

const emptyVoucherObject = {
  code: '',
  discountType: 'percentage',
  discount: 0,
  limitedTo: [],
  validFrom: '',
  validTo: '',
  count: 0,
}

const VoucherForm = ({ onSave, onCancel, voucher }) => {
  const [voucherObject, setVoucherObject] = useState(emptyVoucherObject)
  const { response, error, loading, executeRequest } = useAxios({
    manual: true,
  })

  const handleSubmit = async event => {
    event.preventDefault()

    await executeRequest({
      method: !voucherObject.id ? 'post' : 'put',
      url: !voucherObject.id ? '/vouchers' : `/vouchers/${voucherObject.id}`,
      data: voucherObject,
    })

    if (onSave instanceof Function) {
      onSave(voucherObject)
    }

    console.log('done saving', response)
  }

  const handleChange = (event, property) => {
    const { value } = event.target
    if (property !== 'limitedTo') {
      setVoucherObject(prev => ({ ...prev, [property]: value }))
    } else {
      const limitedTo = typeof value === 'string' ? value.split(',') : value
      console.log('setting limited to', limitedTo)
      setVoucherObject(prev => ({ ...prev, ['limitedTo']: limitedTo }))
    }
  }

  const handleCancel = () => {
    if (onCancel instanceof Function) {
      onCancel(voucherObject)
    }
  }

  useEffect(() => {
    setVoucherObject(voucher || emptyVoucherObject)
  }, [voucher, setVoucherObject])

  console.log('rendering', voucherObject)

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container direction="column">
          <Grid item>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Kuponkód</TableCell>
                  <TableCell>
                    <TextField
                      required
                      id="code"
                      label="Kuponkód"
                      value={voucherObject.code}
                      onChange={event => handleChange(event, 'code')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kedvezmény típusa</TableCell>
                  <TableCell>
                    <Select
                      id="discountType"
                      required
                      value={voucherObject.discountType || 'percentage'}
                      label="Kedvezmény típusa"
                      onChange={event => handleChange(event, 'discountType')}
                    >
                      <MenuItem value={'percentage'}>
                        Százalékos kedvezmény
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kedvezmény mértéke (%)</TableCell>
                  <TableCell>
                    <TextField
                      required
                      id="discount"
                      label="Kedvezmény (%)"
                      value={voucherObject.discount}
                      onChange={event => handleChange(event, 'discount')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Érvényesség kezdete</TableCell>
                  <TableCell>
                    <input
                      type="datetime-local"
                      value={voucherObject.validFrom}
                      onChange={event => handleChange(event, 'validFrom')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Érvényesség vége</TableCell>
                  <TableCell>
                    <input
                      type="datetime-local"
                      value={voucherObject.validTo}
                      onChange={event => handleChange(event, 'validTo')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Darabszám</TableCell>
                  <TableCell>
                    <TextField
                      required
                      id="count"
                      label="Darabszám"
                      value={voucherObject.count}
                      onChange={event => handleChange(event, 'count')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Felhasználás köre</TableCell>
                  <TableCell>
                    <Select
                      id="limitedTo"
                      label="Felhasználás köre"
                      multiple
                      value={voucherObject.limitedTo}
                      onChange={event => handleChange(event, 'limitedTo')}
                      //input={<OutlinedInput label="Tag" />}
                      renderValue={selected => {
                        return selected
                          .map(s => limits.find(l => l.id === s).text)
                          .join(', ')
                      }}
                    >
                      {limits.map(limit => (
                        <MenuItem key={limit.id} value={limit.id}>
                          <Checkbox
                            checked={
                              voucherObject &&
                              voucherObject.limitedTo &&
                              voucherObject.limitedTo.indexOf(limit.id) > -1
                            }
                          />
                          <ListItemText primary={limit.text} />
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item style={{ marginTop: '2rem' }}>
            <Button type="submit" variant="contained" color="primary">
              Mentés
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              style={{ marginLeft: '2rem' }}
            >
              Mégsem
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

VoucherForm.displayName = 'VoucherForm'

VoucherForm.propTypes = {}

VoucherForm.defaultProps = {}

export default VoucherForm
export { VoucherForm }
