import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  card: {
    boxShadow: 'none',
  },
})

const NdaCardList = ({ nda, classes, formatDate }) => (
  <React.Fragment>
    {nda.map(e => {
      return (
        <Card className={classes.card} key={e.id}>
          <CardContent>
            <Typography component="p">{`ID: ${e.id}`}</Typography>
            <Typography component="p">{`Név: ${e.name}`}</Typography>
            <Typography component="p">{`Email: ${e.email}`}</Typography>
            <Typography component="p">{`Cím: ${e.address}`}</Typography>
            <Typography component="p">
              Létrehozás ideje:
              {formatDate(e.createdAt)}
            </Typography>
          </CardContent>
        </Card>
      )
    })}
  </React.Fragment>
)

export default withStyles(styles)(NdaCardList)
