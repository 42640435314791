import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import Search from '../common/Search'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  btnContainer: {
    textAlign: 'right',
  },
  searchContainer: {
    textAlign: 'left',
  },
  link: {
    textDecoration: 'none',
    position: 'relative',
    top: '-30px',
  },
})

const AddressTable = ({
  addresses,
  classes,
  handleAdd,
  handleEdit,
  handleDelete,
  handleSearch,
}) => (
  <React.Fragment>
    <Grid container justify="center">
      <Grid className={classes.searchContainer} item md={8}>
        <Link
          className={classes.link}
          to={{ pathname: window.location.pathname.replace('/addresses', '') }}
        >
          <ArrowBackIcon />
        </Link>
        <Search handleSearch={handleSearch} />
      </Grid>
      <Grid className={classes.btnContainer} item md={4}>
        <Button
          onClick={handleAdd}
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Név</TableCell>
          <TableCell>Cím</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {addresses.map(address => {
          return (
            <TableRow key={address.id}>
              <TableCell>{address.userGivenName}</TableCell>
              <TableCell>{address.stringRepresentation}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    handleEdit(address)
                  }}
                  className={classes.button}
                  aria-label="Edit"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDelete({ id: address.id })
                  }}
                  className={classes.button}
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </React.Fragment>
)

export default withStyles(styles)(AddressTable)
