import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import moment from 'moment'
require('moment/locale/hu')

const styles = theme => ({})

const CouponTable = ({ requestsWithCoupons, formatDate }) => {
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Kupon kód</TableCell>
            <TableCell>Felhasználó</TableCell>
            <TableCell>Felhasználó Email</TableCell>
            <TableCell>Felhasználás ideje</TableCell>
            <TableCell>Felhasználás hete</TableCell>
            <TableCell>Sikeres konzultáció</TableCell>
            <TableCell>Szakember</TableCell>
            <TableCell>Szakember Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requestsWithCoupons.map(request => {
            const weekOfUsage = moment(request.createdAt).week()
            return (
              <TableRow key={request.id} style={{ backgroundColor: weekOfUsage % 2 ? 'rgba(0, 0, 0, 0.08)' : 'white' }}>
                <TableCell>{request.couponCode}</TableCell>
                <TableCell>{request.requester.name}</TableCell>
                <TableCell>{request.requester.email}</TableCell>
                <TableCell>{formatDate(request.createdAt)}</TableCell>
                <TableCell>{weekOfUsage}</TableCell>
                <TableCell>{request.successful ? 'igen' : 'nem'}</TableCell>
                <TableCell>
                  {request.provider
                    ? `${request.provider.namePrefix} ${request.provider.lastName} ${request.provider.firstName}`
                    : 'Nincs'}
                </TableCell>
                <TableCell>
                  {request.provider ? request.provider.email : 'Nincs'}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default withStyles(styles)(CouponTable)
