import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    marginBottom: '20px',
    width: '100%',
  },
  headerContainer: {
    position: 'relative',
    top: '-16px',
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  headerText: {
    color: 'white',
    padding: '10px',
  },
  card: {
    boxShadow: 'none',
  },
  response: {
    marginBottom: '20px',
  }
})

const CallHistoryExtraInfoCardList = ({
  callExtraInfo,
  classes,
  onBackClick,
}) => (
  <React.Fragment>
    <Grid className={classes.headerContainer} item xs={12}>
      <Typography
        className={classes.headerText}
        variant="title"
        gutterBottom
      >
        <span>Hívás adatok</span>
      </Typography>
    </Grid>
    <Card className={classes.card}>
      <CardContent>
        <Grid className={classes.response}>
          <Typography component="p">
            <b>{'Orvos válasza:'}</b>
          </Typography>
          <Typography component="p">
            {`${callExtraInfo.providerResponse || ``}`}
          </Typography>
        </Grid>
        <Grid>
          <Typography component="p">
            <b>{'Tünetek:'}</b>
          </Typography>
          {callExtraInfo.requestTypes.map(requestType => {
            return (
              <Typography component="p" key={callExtraInfo.id}>
                {`${requestType.title}`}
              </Typography>
            )
          })}
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          onClick={onBackClick}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Vissza
        </Button>
      </CardActions>
    </Card>
  </React.Fragment>
)

export default withStyles(styles)(CallHistoryExtraInfoCardList)
