import jwtDecode from 'jwt-decode'

export default function(token) {
  if (!token) {
    return false
  }

  const { exp } = jwtDecode(token)

  return exp > new Date().getTime() / 1000
}
