import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'

import { getNda } from '../../modules/admin'

import NdaTable from '../../components/nda/NdaTable'
import NdaCardList from '../../components/nda/NdaCardList'

import formatDate from '../../utils/dateFormatter'

class NdaContainer extends React.Component {
  componentDidMount() {
    this.props.getNda()
  }

  render() {
    const { nda } = this.props
    return (
      <React.Fragment>
        <Hidden only={['sm', 'xs']}>
          <NdaTable nda={nda} formatDate={formatDate} />
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <NdaCardList nda={nda} formatDate={formatDate} />
        </Hidden>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  nda: admin.nda,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNda,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(NdaContainer)
