import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import SearchIcon from '@material-ui/icons/Search'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
})

class Search extends Component {
  state = {
    searchText: '',
  }

  handleKeyPress = event => {
    const { searchText } = this.state
    const { handleSearch } = this.props
    if (event.key === 'Enter') {
      handleSearch(searchText)
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  render() {
    const { searchText } = this.state
    const { handleSearch, classes } = this.props
    return (
      <Fragment>
        <TextField
          id="search"
          label="Keresés..."
          value={searchText}
          onChange={this.handleChange('searchText')}
          onKeyPress={this.handleKeyPress}
          margin="normal"
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            handleSearch(searchText)
          }}
        >
          <SearchIcon />
        </Button>
      </Fragment>
    )
  }
}

export default withStyles(styles)(Search)
