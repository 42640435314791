/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: '/api/platform/',
})

axiosInstance.interceptors.request.use(config => ({
  ...config,
  baseURL: '/api/platform/', // eslint-disable-line
  headers: {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}))

const useAxios = ({
  url,
  method,
  body = null,
  headers = null,
  manual = false,
}) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const executeRequest = configOverride => {
    setLoading(true)

    if (!configOverride) {
      axiosInstance[method.toLowerCase()](
        url,
        JSON.parse(headers),
        JSON.parse(body)
      )
        .then(res => setResponse(res.data))
        .catch(err => setError(err))
        .finally(() => setLoading(false))
    } else {
      axiosInstance({
        method,
        url,
        data: JSON.parse(body),
        headers: JSON.parse(headers),
        ...configOverride,
      })
        .then(r => setResponse(r.data))
        .catch(err => setError(err))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (manual === false) {
      executeRequest()
    }
  }, [method, url, body, headers])

  return {
    response,
    error,
    loading,
    executeRequest,
  }
}

useAxios.displayName = 'useAxios'

export default useAxios
export { useAxios }
