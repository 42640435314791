import React, { Suspense } from 'react'
import CheckedRoute from '../common/CheckedRoute'
import { Switch, withRouter, Redirect } from 'react-router-dom'

import DoctorContainer from '../../containers/doctor/DoctorContainer'
import PatientContainer from '../../containers/patient/PatientContainer'
import SymptomContainer from '../../containers/symptom/SymptomContainer'
import TipContainer from '../../containers/tip/TipContainer'
import FeedbackContainer from '../../containers/feedback/FeedbackContainer'
import AddressContainer from '../../containers/address/AddressContainer'
import CountryContainer from '../../containers/country/CountryContainer'
import ProfileContainer from '../../containers/profile/ProfileContainer'
import StatusContainer from '../../containers/status/StatusContainer'
import CallHistoryContainer from '../../containers/callHistory/CallHistoryContainer'
import NdaContainer from '../../containers/nda/NdaContainer'
import CouponContainer from '../../containers/coupon/CouponContainer'
import { VouchersView } from '../../views'

const Main = () => {
  return (
    <Switch>
      <Redirect exact from="/dashboard" to="/dashboard/doctors" />
      <CheckedRoute
        path="/dashboard/status"
        needsAuth={true}
        component={withRouter(StatusContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/doctors"
        needsAuth={true}
        component={withRouter(DoctorContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/patients"
        needsAuth={true}
        component={withRouter(PatientContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/nda"
        needsAuth={true}
        component={withRouter(NdaContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/symptoms"
        needsAuth={true}
        component={withRouter(SymptomContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/tips"
        needsAuth={true}
        component={withRouter(TipContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/calls"
        needsAuth={true}
        component={withRouter(CallHistoryContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/countries"
        needsAuth={true}
        component={withRouter(CountryContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/feedbacks"
        needsAuth={true}
        component={withRouter(FeedbackContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/vouchers"
        needsAuth={true}
        component={withRouter(VouchersView)}
        exact
      />
      <CheckedRoute
        path="/dashboard/coupon-stats"
        needsAuth={true}
        component={withRouter(CouponContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/patients/addresses"
        needsAuth={true}
        component={withRouter(AddressContainer)}
        exact
      />
      <CheckedRoute
        path="/dashboard/doctors/addresses"
        needsAuth={true}
        component={withRouter(AddressContainer)}
        exact
      />
      <Suspense fallback={<h1>Loading profile...</h1>}>
        <CheckedRoute
          path="/dashboard/profile"
          needsAuth={true}
          component={withRouter(ProfileContainer)}
          exact
        />
      </Suspense>
    </Switch>
  )
}

export default Main
