import React, { Fragment } from 'react'
import {
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core'

import './AddressForm.css'
import { useTranslation } from 'react-i18next'

const AddressForm = ({ onChange, address, countries }) =>{
  const { t } = useTranslation('addressForm')
  return (
    <Fragment>
      <p style={{ paddingLeft: 20 }}>
        <b>
          {t('title')}<sup>*</sup>
        </b>
      </p>
      <p style={{ paddingLeft: 20 }}>
        <b>
          <sup>*</sup>{t('description')}
        </b>
      </p>
      <TextField
        className="address_form_input"
        label={t('company')}
        onChange={e => onChange('userGivenName', e.target.value, 'address')}
        required
        margin="normal"
        value={address.userGivenName || ''}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl required className="address_form_input">
        <InputLabel>{t('country')}</InputLabel>
        <Select
          value={address.countryCode || ''}
          onChange={e => onChange('countryCode', e.target.value, 'address')}
        >
          {countries.map((country, i) => (
            <MenuItem key={i} value={country.countryCode}>
              {country.countryName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className="address_form_input"
        label={t('zipCode')}
        onChange={e => {
          if (!isNaN(+e.target.value)) {
            onChange('postalCode', +e.target.value, 'address')
          }
        }}
        required
        margin="normal"
        value={address.postalCode || ''}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        className="address_form_input"
        label={t('city')}
        onChange={e => onChange('city', e.target.value, 'address')}
        required
        margin="normal"
        value={address.city || ''}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        className="address_form_input"
        label={t('address')}
        onChange={e => onChange('streetAddress', e.target.value, 'address')}
        required
        margin="normal"
        value={address.streetAddress || ''}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        className="address_form_input"
        label={t('addressDetails')}
        onChange={e => onChange('misc', e.target.value, 'address')}
        margin="normal"
        value={address.misc || ''}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        className="address_form_input"
        label={t('taxNumber')}
        onChange={e => onChange('vatNumber', e.target.value, 'address')}
        required
        margin="normal"
        value={address.vatNumber || ''}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Fragment>
  )
}
export default AddressForm
