import React from 'react'
import axios from 'axios'
import saveAs from 'file-saver'
import { CircularProgress } from '@material-ui/core'

class DownloadLink extends React.Component {
  state = {
    downloading: false,
  }

  async download(userId, link) {
    try {
      this.setState({
        downloading: true,
      })

      const response = await axios.post(
        '/api/platform/admin/user/providerData/download/',
        {
          userId,
          link,
        }
      )
      const { file: fileStr, fileName } = response.data
      const fileBlob = await fetch(fileStr).then(res => res.blob())
      saveAs(fileBlob, fileName)

      this.setState({
        downloading: false,
      })
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const { userId, downloadLink } = this.props
    const { downloading } = this.state
    return (
      <div>
        {downloadLink ? (
          <React.Fragment>
            {downloading ? (
              <CircularProgress />
            ) : (
              <button
                type="button"
                className="download_button"
                onClick={() => this.download(userId, downloadLink)}
              >
                Letöltés
              </button>
            )}
          </React.Fragment>
        ) : (
          <p>Nem elérhető</p>
        )}
      </div>
    )
  }
}

export default DownloadLink
