import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'

import { withStyles } from '@material-ui/core/styles'
import Logo from '../../assets/images/logo.png'
import BestPracticeContent from './BestPracticeContent'

const styles = theme => ({
  container: {
    height: '100vh',
    background:
      'linear-gradient( 45deg, rgb(63,167,240) 0%, rgb(240,30,90) 100%)',
    overflow: 'auto'
  },
  registrationCardGridItem: {
    textAlign: 'center',
    position: 'relative',
    top: '5%',
    maxHeight: '90vh',
  },
  logoContainer: {
    position: 'absolute',
    top: '2%',
  },
})

const Registration = ({ classes, error, onSubmit }) => (
  <React.Fragment>
    <Grid
      container
      className={classes.container}
      alignItems="center"
      direction="row"
      justify="center"
    >
      <Grid className={classes.logoContainer} item xs={10} sm={6} md={6} lg={3}>
        <img alt="logo" className={classes.image} width={150} src={Logo} />
      </Grid>
      <Grid
        className={classes.registrationCardGridItem}
        item
        xs={11}
        sm={9}
        md={8}
        lg={6}
      >
        <Fade in={true} timeout={1000}>
          <Paper>
            <BestPracticeContent error={error} onSubmit={onSubmit} />
          </Paper>
        </Fade>
      </Grid>
    </Grid>
  </React.Fragment>
)

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Registration)
