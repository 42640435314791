import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hidden from '@material-ui/core/Hidden'

import {
  getPatients,
  createUser,
  updateUser,
  deleteUser,
} from '../../modules/admin'

import PatientTable from '../../components/patient/PatientTable'
import PatientForm from '../../components/patient/PatientForm'
import PatientCardList from '../../components/patient/PatientCardList'

import confirmDelete from '../../utils/confirmDelete'
import formatDate from '../../utils/dateFormatter'

class PatientContainer extends React.Component {
  state = {
    showPatientForm: false,
    selectedPatient: {},
  }
  componentDidMount() {
    this.props.getPatients()
  }

  showForm = () => {
    this.setState({
      showPatientForm: true,
    })
  }

  handleEdit = patient => {
    this.setState({
      selectedPatient: patient,
      showPatientForm: true,
    })
  }

  submitForm = async payload => {
    if (!payload.id) {
      await this.props.createUser({ ...payload, userTypeId: 2 })
    } else {
      await this.props.updateUser({ ...payload, userTypeId: 2 })
    }
    await this.props.getPatients()
    this.setState({
      showPatientForm: false,
    })
  }

  handleDelete = async ({ id }) => {
    const canDelete = await confirmDelete(this.props.patients, id, 'email')
    if (canDelete) {
      await this.props.deleteUser({ id })
      await this.props.getPatients()
    }
  }

  handleSearch = async searchText => {
    await this.props.getPatients(searchText)
  }

  handleBack = () => {
    this.setState({
      showPatientForm: false,
      selectedPatient: {},
    })
  }

  render() {
    const { patients } = this.props
    const { showPatientForm, selectedPatient } = this.state
    return (
      <React.Fragment>
        {!showPatientForm && (
          <React.Fragment>
            <Hidden only={['sm', 'xs']}>
              <PatientTable
                handleAdd={this.showForm}
                handleSearch={this.handleSearch}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                formatDate={formatDate}
                patients={patients}
              />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <PatientCardList
                handleAdd={this.showForm}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                formatDate={formatDate}
                patients={patients}
              />
            </Hidden>
          </React.Fragment>
        )}
        {showPatientForm && (
          <PatientForm
            patient={selectedPatient}
            onBackClick={this.handleBack}
            onSubmit={this.submitForm}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  patients: admin.patients,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPatients,
      createUser,
      updateUser,
      deleteUser,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PatientContainer)
