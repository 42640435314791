import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import TypeSelector from '../common/TypeSelector'

const styles = theme => ({
  button: {
    margin: 0,
    marginBottom: '20px',
    width: '100%',
  },
  editButton: {
    width: '100%',
  },
  btnContainer: {
    textAlign: 'right',
  },
  selectContainer: {
    alignItems: 'center',
  },
  card: {
    boxShadow: 'none',
  },
  onlineIcon: {
    color: 'limegreen',
    'vertical-align': 'bottom',
  },
  offlineIcon: {
    color: 'grey',
    'vertical-align': 'bottom',
  },
})

const DoctorCardList = ({
  doctors,
  classes,
  handleTypeSelected,
  providerTypes,
  selectedProviderType,
  handleAdd,
  handleEdit,
  handleExport,
  handleDelete,
  handleValidationSwitch,
  formatDate,
}) => (
  <React.Fragment>
    <div className={classes.btnContainer}>
      <Button
        onClick={handleAdd}
        size="small"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        <AddIcon />
      </Button>
      <Button
        onClick={handleExport}
        size="small"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Export
      </Button>
    </div>
    <div className={classes.selectContainer}>
      <TypeSelector
        types={providerTypes}
        selectedType={selectedProviderType}
        displayParam="name"
        handleTypeSelected={handleTypeSelected}
      />
    </div>
    {doctors.map(doctor => {
      return (
        <Card className={classes.card} key={doctor.id}>
          <CardContent>
            <Typography component="p">{`ID: ${doctor.id}`}</Typography>
            <Typography component="p">
              {`Titulus: ${doctor.namePrefix || ``}`}
            </Typography>
            <Typography component="p">
              {`Vezetéknév: ${doctor.lastName}`}
            </Typography>
            <Typography component="p">
              {`Keresztnév: ${doctor.firstName}`}
            </Typography>
            <Typography component="p">{`Email: ${doctor.email}`}</Typography>
            <Typography component="p">
              {`Telefonszám: ${doctor.phoneNumber}`}
            </Typography>
            {doctor.birthday && (
              <Typography component="p">
                {`Születési dátum: ${doctor.birthday}`}
              </Typography>
            )}
            <Typography component="p">
              Bejelentkezve:
              <FiberManualRecordIcon
                className={
                  doctor.availableSince
                    ? classes.onlineIcon
                    : classes.offlineIcon
                }
              />
            </Typography>
            <Typography component="p">
              Regisztráció ideje:
              {formatDate(doctor.createdAt)}
            </Typography>
            <Typography component="p">
              Jóváhagyott:
              <Switch
                checked={doctor.validated}
                onChange={() => {
                  handleValidationSwitch(doctor)
                }}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                handleEdit(doctor)
              }}
              size="small"
              variant="contained"
              color="primary"
              className={classes.editButton}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                handleDelete({ id: doctor.id })
              }}
              size="small"
              variant="contained"
              color="primary"
              className={classes.editButton}
            >
              <DeleteIcon />
            </Button>
          </CardActions>
        </Card>
      )
    })}
  </React.Fragment>
)

export default withStyles(styles)(DoctorCardList)
